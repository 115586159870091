import Job from '@/views/Job';
import Role from '@/views/Role';
import RoleSet from '@/views/RoleSet';
import App from '@/views/App';
import versionManage from '@/views/versionManage';
import versionManageDiff from '@/views/versionManage/diff';
import versionManageDetail from '@/views/versionManage/detail';
import User from '@/views/User';
import Menu from '@/views/Menu';
import Api from '@/views/Api';
import File from '@/views/File';
import Element from '@/views/Element';
import Object from '@/views/Object';
import ObjectField from '@/views/ObjectField';
import Permission from '@/views/Permission';
import Query from '@/views/Query';
import Login from '@/views/Login';
import LoginMiddle from '@/views/middleLogin';
import Layout from '@/layout';
import Empty from '@/layout/Empty';
import LayoutThreeMenu from '@/layout-threeMenu';
import LayoutThree from '@/layout-three';
import metadata from '@/views/metadata';
import metadataFields from '@/views/metadata/metadata-fields';
import indexs from '@/views/metadata/indexs';
import versions from '@/views/metadata/versions';
import versionsDetail from '@/views/metadata/versions/detail';
import versionsDiff from '@/views/metadata/versions/diff';
import rule from '@/views/metadata/rule';
import ruleEdit from '@/views/metadata/rule/edit';
import processManage from '@/views/workflow/processManage';
import workflow from '@/views/workflow';
import workflowDetail from '@/views/workflow/detail';
import workflowEvent from '@/views/workflow/event-manage';
import workflowNoitceEventDetail from '@/views/workflow/component/notice-event-detail';
import workflowCloudEventDetail from '@/views/workflow/component/cloud-event-detail';
import processLog from '@/views/workflow/component/process-log';
import audit from '@/views/audit';
import Account from '@/views/Account';
import updateManage from '@/views/update/updateManage';
import noticeList from '@/views/notice';
import noticeDetail from '@/views/notice/noticeDetail';
import internation from '@/views/internation';
import Employeeorg from '@/views/employeeorg';
import UserGroup from '@/views/UserGroup';
import JsonEdit from '@/views/JsonEdit';

const routes = [
  {
    path: '/',
    redirect: { name: 'home' },
  },
  {
    path: '',
    component: Layout,
    name: '',
    children: [
      {
        path: 'home',
        meta: {
          title: '主页',
        },
        name: 'home',
        component: () => import('@/views/index'),
      },
    ],
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      title: '登录',
    },
  },
  {
    path: '/callback/login',
    component: LoginMiddle,
    name: 'callback',
    meta: {
      title: '单点登录中间页',
    },
  },
  {
    path: '/tool',
    component: Layout,
    name: 'tool',
    redirect: { name: 'tool_query' },
    meta: {
      title: '工具管理',
    },
  },
  {
    path: '/personnel',
    component: Layout,
    name: 'personnel',
    redirect: { name: 'personnel_account' },
    meta: {
      title: '用户和权限',
    },
    children: [
      {
        path: 'account',
        meta: { title: '账号登录' },
        name: 'personnel_account',
        component: Account,
      },
      {
        path: 'user',
        meta: { title: '用户权限' },
        name: 'personnel_user',
        component: User,
      },
      {
        path: 'user_manage',
        meta: { title: '用户管理' },
        name: 'user_manage',
        component: () => import('@/views/UserManage/index'),
      },
      {
        path: 'job',
        meta: { title: '岗位管理' },
        name: 'personnel_job',
        component: Job,
      },
      {
        path: 'role',
        meta: { title: '角色/权限包管理' },
        name: 'personnel_role',
        // component: Role,
        redirect: { name: 'role-list' },
        component: Empty,
        children: [
          {
            path: 'role-list',
            meta: { title: '角色/权限包管理' },
            name: 'role-list',
            component: Role,
          },
          {
            path: 'perm-set-manage',
            meta: { title: '权限包分配' },
            name: 'perm-set-manage',
            component: () => import('@/views/Role/PermSetManage/index'),
          },
        ],
      },
      {
        path: 'role_set',
        meta: { title: '角色集管理' },
        name: 'personnel_role_set',
        component: RoleSet,
      },
      {
        path: 'permission',
        meta: { title: '权限分配' },
        name: 'permission_setting',
        component: Permission,
      },
      {
        path: 'permission_old',
        meta: { title: '权限分配(旧)' },
        name: 'permission_setting_old',
        component: () => import('@/views/Permission/old-index.vue'),
      },
      {
        path: 'employeeorg',
        meta: { title: '员工组织层级' },
        name: 'personnel_employeeorg',
        component: Employeeorg,
      },
      {
        path: 'user-group',
        meta: { title: '公共用户组' },
        name: 'user-group',
        component: UserGroup,
      },
    ],
  },
  {
    path: '/system',
    component: Layout,
    name: 'system',
    redirect: { name: 'system_app' },
    meta: {
      title: '系统设置',
    },
    children: [
      {
        path: 'app',
        meta: { title: '应用管理' },
        name: 'system_app',
        component: App,
      },
      {
        path: 'index',
        meta: { title: '通知中心' },
        name: 'notice-list',
        component: noticeList,
      },
      {
        path: 'logo-custom',
        meta: { title: 'LOGO自定义' },
        name: 'logo-custom',
        component: () => import('@/views/tenant/logo'),
      },
      {
        path: 'log',
        meta: { title: '审计日志' },
        name: 'audit-log',
        component: audit,
      },
      {
        path: 'token',
        meta: { title: 'Token管理' },
        name: 'token',
        component: () => import('@/views/token'),
      },
      {
        path: 'version-manage',
        meta: { title: '版本管理' },
        name: 'version-manage',
        component: versionManage,
      },
      {
        path: 'version-manage-diff',
        meta: { title: '版本管理差异' },
        name: 'version-manage-diff',
        component: versionManageDiff,
      },
      {
        path: 'version-manage-detail',
        meta: { title: '版本管理详情' },
        name: 'version-manage-detail',
        component: versionManageDetail,
      },
      {
        path: 'water-mark-setting',
        meta: { title: '水印设置' },
        name: 'water-mark-setting',
        component: () => import('@/views/WaterMark'),
      },
      {
        path: 'help-guide-setting',
        meta: { title: '帮助指引' },
        name: 'help-guide-setting',
        component: () => import('@/views/HelpGuide'),
      },
      {
        path: 'help-guide-edit',
        meta: { title: '帮助指引' },
        name: 'help-guide-edit',
        component: () => import('@/views/HelpGuide/edit'),
      },
    ],
  },
  {
    path: '/power',
    component: Layout,
    name: 'power',
    meta: {
      title: '对象和布局',
    },
    children: [
      {
        path: 'new-metadata',
        meta: { title: '元数据' },
        name: 'new-metadata',
        component: LayoutThreeMenu,
        redirect: { name: 'metadata-details' },
        children: [
          {
            path: 'metadata-details',
            name: 'metadata-details',
            component: metadata,
          },
          {
            path: 'overview',
            name: 'overview',
            component: () => import('@/views/metadata/overview/index'),
          },
          {
            path: 'field-collect',
            name: 'field-collect',
            component: () => import('@/views/metadata/field-collect/index'),
          },
          {
            path: 'metadata-layout',
            name: 'metadata-layout',
            component: () => import('@/views/metadata/layout/index'),
          },
          {
            path: 'object-work-flow',
            name: 'object-work-flow',
            component: () => import('@/views/metadata/work-flow/index'),
          },
          {
            path: 'object-permission',
            name: 'object-permission',
            component: () => import('@/views/metadata/permission/index'),
          },
          {
            path: 'metadata-rule',
            name: 'metadata-rule',
            component: () => import('@/views/metadata/rule-v2/index'),
          },
          {
            path: 'metadata-trigger',
            name: 'metadata-trigger',
            component: () => import('@/views/metadata/trigger-v2/index'),
          },
          {
            path: 'share-rule',
            name: 'share-rule',
            component: () => import('@/views/metadata/share-rule/index'),
          },
          {
            path: 'query-filter',
            name: 'query-filter',
            component: () => import('@/views/metadata/query-filter/index'),
          },
        ],
      },
      // 旧的元数据列表页
      {
        path: 'metadata',
        meta: { title: '元数据' },
        name: 'metadata',
        component: () => import('@/views/metadata/old-index.vue'),
      },
      {
        path: 'layout-manage',
        meta: { title: '布局管理' },
        name: 'layout-manage',
        component: LayoutThree,
        redirect: { name: 'layout-manage-list' },
        children: [
          {
            path: 'layout-manage-list',
            name: 'layout-manage-list',
            component: () => import('@/views/layoutManage/index'),
          },
          {
            path: 'layout-detail',
            meta: { title: '字段详情' },
            name: 'layout-detail',
            component: () => import('@/views/layoutManage/detail'),
          },
          {
            path: 'layout-search',
            meta: { title: '配置搜索' },
            name: 'layout-search',
            component: () => import('@/views/layoutManage/layout-search'),
          },
        ],
      },
      {
        path: 'menu',
        meta: { title: '菜单设置' },
        name: 'system_menu',
        component: Menu,
      },
      {
        path: 'new-system_menu',
        meta: { title: '菜单设置' },
        name: 'new-system_menu',
        component: () => import('@/views/Menu-new/index.vue'),
      },
      {
        path: 'api',
        meta: { title: '接口设置' },
        name: 'system_api',
        component: Api,
      },
      {
        path: 'kv-manage',
        meta: { title: '关键值（KV）' },
        name: 'kv-manage',
        component: () => import('@/views/metadata/kvManage/index'),
      },
      {
        path: 'query',
        meta: { title: '元数据查询' },
        name: 'tool_query',
        component: Query,
      },
      {
        path: 'file',
        meta: { title: '文件管理' },
        name: 'system_file',
        component: File,
      },
      {
        path: 'element',
        meta: { title: '页面元素' },
        name: 'system_element',
        component: Element,
      },
      {
        path: 'object',
        meta: { title: '数据管理' },
        name: 'system_object',
        component: Object,
      },
      {
        path: 'object_field',
        meta: { title: '字段管理' },
        name: 'system_object_field',
        component: ObjectField,
      },
      {
        path: 'tag',
        meta: { title: '标签管理' },
        name: 'tag',
        component: () => import('@/views/Tag/index.vue'),
      },
      {
        path: 'tag-user',
        meta: { title: '更新标签人员' },
        name: 'tag-user',
        component: () => import('@/views/Tag/TagUser.vue'),
      },
      {
        path: 'permission',
        meta: { title: '权限分配' },
        name: 'permission_setting',
        component: Permission,
      },
    ],
  },
  {
    path: '/meta',
    component: Layout,
    name: 'meta',
    meta: {
      title: '元数据管理',
    },
    children: [
      {
        path: 'metadata-fields',
        meta: { title: '字段详情' },
        name: 'metadata-fields',
        component: metadataFields,
      },
      {
        path: 'indexs',
        meta: { title: '索引列表' },
        name: 'indexs',
        component: indexs,
      },
      {
        path: 'versions',
        meta: { title: '版本' },
        name: 'versions',
        component: versions,
      },
      {
        path: 'versions/detail',
        meta: { title: '版本详情' },
        name: 'versions-detail',
        component: versionsDetail,
      },
      {
        path: 'versions/diff',
        meta: { title: '版本差异' },
        name: 'versions-diff',
        component: versionsDiff,
      },
      {
        path: 'rule',
        meta: { title: '验证规则' },
        name: 'rule',
        component: rule,
      },
      {
        path: 'rule-edit',
        meta: { title: '验证规则' },
        name: 'rule-edit',
        component: ruleEdit,
      },
      {
        path: 'trigger',
        meta: { title: '触发器' },
        name: 'trigger',
        component: () => import('@/views/metadata/trigger'),
      },
      {
        path: 'trigger-edit',
        meta: { title: '设置触发器' },
        name: 'trigger-edit',
        component: () => import('@/views/metadata/trigger/edit'),
      },
    ],
  },
  {
    path: '/tenant',
    component: Layout,
    name: 'tenant',
    meta: {
      title: '租户设置',
    },
    children: [
      {
        path: 'logo-custom',
        meta: { title: 'LOGO自定义' },
        name: 'logo-custom',
        component: () => import('@/views/tenant/logo'),
      },
      {
        path: 'internationalization',
        meta: { title: '国际化' },
        name: 'internationalization',
        component: internation,
      },
    ],
  },
  {
    path: '/compliance',
    component: Layout,
    name: 'compliance',
    meta: {
      title: '合规引擎',
    },
    children: [
      {
        path: '/meeting-compliance-manage',
        meta: { title: '会议合规集合管理' },
        name: 'meeting-compliance-manage',
        redirect: { name: 'meeting-compliance-manage-list' },
        component: () => import('@/views/compliance/meeting-compliance-manage'),
        children: [
          {
            path: 'meeting-compliance-manage-list',
            name: 'meeting-compliance-manage-list',
            component: () => import('@/views/compliance/meeting-compliance-manage/list'),
          },
          {
            path: 'meeting-compliance-manage-create',
            meta: { title: '新增会议合规集合' },
            name: 'meeting-compliance-manage-create',
            component: () => import('@/views/compliance/meeting-compliance-manage/create'),
          },
          {
            path: 'meeting-compliance-manage-edit',
            meta: { title: '编辑' },
            name: 'meeting-compliance-manage-edit',
            component: () => import('@/views/compliance/meeting-compliance-manage/create'),
          },
          {
            path: 'meeting-compliance-manage-copy',
            meta: { title: '复制' },
            name: 'meeting-compliance-manage-copy',
            component: () => import('@/views/compliance/meeting-compliance-manage/create'),
          },
        ],
      },
    ],
  },
  {
    path: '/approval-process',
    component: Layout,
    name: 'approval-process',
    meta: {
      title: '工作流管理',
    },
    children: [
      {
        path: 'process-manage',
        meta: { title: '流程管理' },
        name: 'process-manage',
        component: processManage,
      },
      {
        path: 'workflow/model',
        meta: { title: '模型管理' },
        name: 'workflow',
        component: workflow,
      },
      {
        path: 'workflow/event-manage',
        meta: { title: '事件管理' },
        name: 'workflow-event-list',
        component: workflowEvent,
      },
      {
        path: 'workflow/noitce-event-detail',
        meta: { title: '审批流通知事件详情' },
        name: 'noitce-event-detail',
        component: workflowNoitceEventDetail,
      },
      {
        path: 'workflow/cloud-event-detail',
        meta: { title: '审批流云函数事件详情' },
        name: 'cloud-event-detail',
        component: workflowCloudEventDetail,
      },
      {
        path: 'workflow/detail',
        meta: { title: '模型管理详情' },
        name: 'workflow-detail',
        component: workflowDetail,
      },
      {
        path: 'workflow/process-log',
        meta: { title: '流程日志' },
        name: 'process-log',
        component: processLog,
      },
    ],
  },
  {
    path: '/cloud',
    component: Layout,
    name: 'cloud',
    meta: {
      title: '云函数',
    },
    children: [
      {
        path: 'cloud-function',
        meta: { title: '云函数列表' },
        name: 'cloud-function',
        component: () => import('@/views/cloudFunction/list'),
      },
      {
        path: 'detail',
        meta: { title: '云函数详情' },
        name: '云函数详情',
        component: () => import('@/views/cloudFunction/detail/index.vue'),
      },
      {
        path: 'publiv-list',
        meta: { title: '发布列表' },
        name: 'publiv-list',
        component: () => import('@/views/cloudFunction/publivList'),
      },
      {
        path: 'timed-task',
        meta: { title: '定时任务' },
        name: 'timed-task',
        component: () => import('@/views/cloudFunction/timedTask'),
      },
      {
        path: 'subscribe',
        meta: { title: '消息订阅' },
        name: 'subscribe',
        component: () => import('@/views/cloudFunction/subscribe'),
      },
      {
        path: 'cloudFunction-seting',
        meta: { title: '设置' },
        name: 'cloudFunction-seting',
        component: () => import('@/views/cloudFunction/seting'),
      },
    ],
  },
  {
    path: '/audit',
    component: Layout,
    name: 'audit',
    meta: {
      title: '权限审计',
    },
  },
  {
    path: '/update',
    component: Layout,
    name: 'update',
    meta: {
      title: '发版管理',
    },
    children: [
      // {
      //   path: 'version-iam',
      //   meta: { title: 'iam版本管理' },
      //   name: 'version-iam',
      //   component: () => import('@/views/version/iam'),
      //   // children: [
      //   //   {
      //   //     path: 'version-iam-remote',
      //   //     meta: { title: ' 远端租户版本' },
      //   //     name: 'version-iam-remote',
      //   //     component: () => import('@/views/version/iam/iam-remote'),
      //   //   },
      //   // ]
      // },
      // {
      //   path: 'version-iam-remote',
      //   meta: { title: ' 远端租户版本' },
      //   name: 'version-iam-remote',
      //   component: () => import('@/views/version/iam/iam-remote'),
      // },
      {
        path: 'iam-entry',
        meta: { title: 'iam版本管理' },
        name: 'iam-entry',
        component: Empty,
        redirect: { name: 'version-iam-new' },
        children: [
          {
            path: 'version-iam-new',
            name: 'version-iam-new',
            meta: { title: 'iam版本管理' },
            component: () => import('@/views/version/iam'),
          },
          {
            path: 'version-iam-remote',
            meta: { title: ' 远端租户版本' },
            name: 'version-iam-remote',
            component: () => import('@/views/version/iam/iam-remote'),
          },
        ],
      },
      {
        path: 'version-iam',
        name: 'version-iam',
        meta: { title: 'iam版本管理' },
        component: () => import('@/views/version/iam'),
      },
      {
        path: 'version-iam-diff',
        meta: { title: 'iam版本管理diff' },
        name: 'version-iam-diff',
        component: () => import('@/views/version/iam/diff'),
      },
      {
        path: 'version-iam-detail',
        meta: { title: 'iam版本管理详情' },
        name: 'version-iam-detail',
        component: () => import('@/views/version/iam/detail'),
      },
      {
        path: 'meta-entry',
        meta: { title: '元数据版本管理' },
        name: 'meta-entry',
        component: Empty,
        redirect: { name: 'version-meta-new' },
        children: [
          {
            path: 'version-meta-new',
            name: 'version-meta-new',
            meta: { title: '元数据版本管理' },
            component: () => import('@/views/version/meta'),
          },
          {
            path: 'version-meta-remote',
            meta: { title: ' 远端租户版本' },
            name: 'version-meta-remote',
            component: () => import('@/views/version/meta/meta-remote'),
          },
        ],
      },
      {
        path: 'version-meta',
        meta: { title: '元数据版本管理' },
        name: 'version-meta',
        component: () => import('@/views/version/meta'),
      },
      {
        path: 'version-meta-diff',
        meta: { title: '元数据版本管理diff' },
        name: 'version-meta-diff',
        component: () => import('@/views/version/meta/diff'),
      },
      {
        path: 'version-meta-detail',
        meta: { title: '元数据版本管理详情' },
        name: 'version-meta-detail',
        component: () => import('@/views/version/meta/detail'),
      },
      {
        path: 'version-all-entry',
        meta: { title: '版本管理' },
        name: 'version-all-entry',
        component: Empty,
        redirect: { name: 'version-all-list' },
        children: [
          {
            path: 'version-all-list',
            meta: { title: '版本列表' },
            name: 'version-all-list',
            component: () => import('@/views/version/all'),
          },
          {
            path: 'version-all-remote',
            meta: { title: '远端租户版本' },
            name: 'version-all-remote',
            component: () => import('@/views/version/all/remote'),
          },
          {
            path: 'version-all-diff',
            meta: { title: '版本diff' },
            name: 'version-all-diff',
            component: () => import('@/views/version/all/diff'),
          },
          {
            path: 'version-all-detail',
            meta: { title: '版本详情' },
            name: 'version-all-detail',
            component: () => import('@/views/version/all/detail'),
          },
        ],
      },
      {
        path: 'updateManage',
        meta: { title: '升级维护管理' },
        name: 'system-update',
        component: updateManage,
      },
    ],
  },
  {
    path: '/notice',
    component: Layout,
    name: 'notice',
    meta: {
      title: '通知设置中心',
    },
    children: [
      {
        path: 'detail',
        meta: { title: '通知详情' },
        name: 'notice-detail',
        component: noticeDetail,
      },
    ],
  },
  {
    path: '/tenant',
    component: Layout,
    name: 'tenant',
    meta: {
      title: '租户设置',
    },
    children: [
      {
        path: 'internationalization',
        meta: { title: '国际化' },
        name: 'internationalization',
        component: internation,
      },
      {
        path: 'single-point-login',
        meta: { title: '单点登录' },
        name: 'single-point-login',
        component: () => import('@/views/singlePointLogin'),
      },
      {
        path: 'single-point-login-detail',
        meta: { title: '单点登录详情' },
        name: 'single-point-login-detail',
        component: () => import('@/views/singlePointLogin/detail'),
      },
    ],
  },
  {
    path: '/help',
    component: Layout,
    name: 'help',
    meta: {
      title: '使用帮助',
    },
  },
  {
    path: '/json-edit',
    component: JsonEdit,
    name: 'json-edit',
    meta: {
      title: '编辑器',
    },
  },
  {
    path: '*',
    redirect: '/login',
  },
];

export default routes;
