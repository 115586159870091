<template>
  <t-drawer
    :visible="show"
    :close-btn="true"
    @close="onClose"
    @confirm="onConfirm"
    size="800px"
    :header="title"
  >
    <div class="tips">
      <div class="tips-icon">
        <t-icon name="help-circle-filled" />
      </div>
      <div>
        <div class="tips-text">1. 公共用户组配置至少选择一项</div>
        <div class="tips-text">2. 公共用户组不可以嵌套父级公共用户组</div>
        <div class="tips-text">3. 公共用户组嵌套层级最大不可超过五层</div>
      </div>
    </div>
    <t-form label-align="top" :data="formData" ref="ruleForm" :rules="formRule">
      <t-form-item label="名称" name="name">
        <t-input placeholder="请输入" v-model="formData.name" />
      </t-form-item>
      <t-form-item label="Code" name="code">
        <t-input :disabled="isEdit" placeholder="请输入" v-model="formData.code" />
      </t-form-item>

      <t-form-item :label="renderLabel" name="grant_leader">
        <t-switch v-model="formData.grant_leader"></t-switch>
      </t-form-item>

      <t-form-item label="备注描述" name="description">
        <t-textarea placeholder="请输入" v-model="formData.description" />
      </t-form-item>

      <t-form-item name="group">
        <div class="form-item">
          <div class="title">
            <span class="main-title">公共用户组配置</span>
            <span class="sub-title">下列内容至少配置一项</span>
          </div>

          <div class="label">用户</div>
          <t-select
            v-model="formData.user"
            multiple
            filterable
            value-type="object"
            :options="userList"
            @search="searchUser"
          ></t-select>

          <div class="label">员工组织</div>
          <t-select v-model="formData.org_codes" multiple filterable :options="orgList"></t-select>

          <div class="label">员工组织及下属</div>
          <t-select
            v-model="formData.sub_org_codes"
            multiple
            filterable
            :options="orgList"
          ></t-select>

          <div class="label">公共用户组</div>
          <t-select
            v-model="formData.group_codes"
            multiple
            filterable
            :options="groupList"
          ></t-select>
        </div>
      </t-form-item>
    </t-form>
  </t-drawer>
</template>

<script>
import { DialogPlugin, MessagePlugin } from 'tdesign-vue';
import { getTdesignFormValidateResult } from '@/utils/util';
import { HelpCircleIcon } from 'tdesign-icons-vue';
import { cloneDeep } from 'lodash';
export default {
  props: {
    appid: {
      type: Number,
      default: 2, //  默认 nges
    },
    show: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: NaN, //  默认 nges
    },
  },
  data() {
    return {
      formData: {
        name: '',
        code: '',
        grant_leader: true,
        description: '',
        user_accounts: [], // 输入框需要加 value-type="object"，否则样式存在问题，具体可以尝试下
        org_codes: [],
        sub_org_codes: [],
        group_codes: [],
        user: [],
      },
      formRule: {
        name: [{ required: true, message: '请输入名称', trigger: 'change' }],
        code: [{ required: true, message: '请输入Code', trigger: 'change' }],
        group: [{ validator: this.validateGroup }],
      },
      userList: [],
      orgList: [],
      groupList: [],
    };
  },
  computed: {
    title() {
      return this.isEdit ? '编辑公共用户组' : '新建公共用户组';
    },
  },
  watch: {
    show(show) {
      if (show) {
        this.initFormData();
        this.searchUser();
        this.searchOrgs();
        this.searchGroup();
        setTimeout(() => {
          this.$refs.ruleForm.clearValidate();
        }, 100);
      }
    },
  },
  async mounted() {},
  methods: {
    // eslint-disable-next-line no-unused-vars
    renderLabel(h) {
      return (
        <span>
          <span style="margin-right: 12px">授权员工组织上级可访问</span>
          <t-popup content="开关打开时，该公共用户组成员使用共享规则、手动共享、自定义filter分享的数据同时会授权给其员工组织上级用户一致的访问权限">
            <HelpCircleIcon></HelpCircleIcon>
          </t-popup>
        </span>
      );
    },
    validateGroup() {
      return new Promise((resolve) => {
        if (
          this.formData.user.length === 0 &&
          this.formData.group_codes.length === 0 &&
          this.formData.sub_org_codes.length === 0 &&
          this.formData.org_codes.length === 0
        ) {
          resolve({
            result: false,
            message: '公共用户组配置至少选择一项',
            type: 'error',
          });
        } else {
          resolve({ result: true });
        }
      });
    },
    async initFormData() {
      if (this.isEdit) {
        const data = await this.$request({
          url: this.$api.getUserGroup,
          data: {
            id: this.id,
          },
        });
        console.log('----initdata----', data);
        this.formData = {
          ...data,
          user: data.users.map((i) => {
            return {
              label: i.name,
              value: i.account,
            };
          }),
        };
      } else {
        this.formData = {
          name: '',
          code: '',
          grant_leader: true,
          description: '',
          user_accounts: [], // 输入框需要加 value-type="object"，否则样式存在问题，具体可以尝试下
          org_codes: [],
          sub_org_codes: [],
          group_codes: [],
          user: [],
          appid: this.appid,
        };
      }
    },
    searchUser(keyword = '') {
      const url = this.$api.getUserList;
      const data = {
        keyword,
        page: 1,
        type: 1,
        page_size: 10,
      };

      this.$request({ url, data, showLoading: false }).then((data) => {
        console.log('------user-data------', data);
        this.userList = data.data.map((i) => {
          return {
            value: i.account,
            label: i.name,
          };
        });
      });
    },
    async searchOrgs() {
      const url = this.$api.getOrganizationList;
      const payload = {
        page: 1,
        page_size: 999999,
        appid: this.appid,
      };
      const { data } = await this.$request({ url, data: payload });
      this.orgList = data.map((item) => {
        return {
          value: item.code,
          label: `${item.name}`,
        };
      });
    },
    async searchGroup(keyword = '') {
      const url = this.$api.getUserGroupList;
      const payload = {
        page: 1,
        page_size: 10,
        appid: this.appid,
        keyword,
      };
      const { data } = await this.$request({ url, data: payload });
      this.groupList = data.map((item) => {
        return {
          value: item.code,
          label: `${item.name}`,
        };
      });
    },
    onClose() {
      // this.$emit('close');
      const confirmDia = DialogPlugin.confirm({
        header: '提示',
        body: '所作更改将不会被保存，确认关闭?',
        confirmBtn: '确定',
        theme: 'warning',
        cancelBtn: '取消',
        onConfirm: () => {
          confirmDia.hide();
          this.$emit('close');
        },
        onClose: () => {
          confirmDia.hide();
        },
      });
    },
    async onConfirm() {
      console.log('------this.formData------', this.formData);
      const valid = getTdesignFormValidateResult(await this.$refs.ruleForm.validate());
      if (!valid) return;
      console.log(this.formData);
      let formData = cloneDeep(this.formData);
      formData = {
        ...formData,
        user_accounts: formData.user.map((item) => item.value),
      };

      // formData.user_accounts = formData.user_accounts.map((item) => item.value);
      if (!this.isEdit) await this.onCreate(formData);
      else await this.onEdit(formData);

      this.$emit('refresh');
    },
    onCascaderDataChange(value) {
      console.log(value);
    },
    async onCreate(formData) {
      console.log('----onCreate---', formData);
      const url = this.$api.addUserGroup;
      await this.$request({ url, data: formData });
      MessagePlugin('success', `新建成功`);
    },

    async onEdit(formData) {
      console.log('----onEdit---', formData);
      const url = this.$api.updateUserGroup;
      await this.$request({
        url,
        data: {
          ...formData,
          id: this.id,
        },
      });
      MessagePlugin('success', `编辑成功`);
    },
  },
};
</script>

<style scoped lang="scss">
.tips {
  display: flex;
  background: rgba(217, 225, 255, 1);
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -16px;
  .tips-icon {
    width: 48px;
    padding-left: 20px;
    color: rgba(0, 119, 255, 1);
  }
  .tips-text {
    font-size: 14px;
  }
}
.form-item {
  width: 800px;
  .title {
    height: 32px;
    background-color: #f4f7fb;
    margin-bottom: 16px;
    .main-title {
      font-size: 16px;
      line-height: 32px;
      font-weight: 700;
    }
    .sub-title {
      font-size: 14px;
      line-height: 32px;
      color: #708091;
      margin-left: 12px;
    }
  }
  .label {
    margin-top: 16px;
  }
}
</style>
