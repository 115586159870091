<template>
  <div class="table-container">
    <div class="top">
      <div class="query-button">
        <el-form :inline="true">
          <el-form-item label="应用">
            <el-select v-model="product" placeholder="应用">
              <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-button type="primary" icon="el-icon-s-order" @click="querySql('querySql')">
          查
        </el-button>
        <el-button type="primary" icon="el-icon-s-order" @click="querySql('insertSql')">
          增
        </el-button>
        <el-button type="primary" icon="el-icon-s-order" @click="querySql('deleteSql')">
          删
        </el-button>
        <el-button type="primary" icon="el-icon-s-order" @click="querySql('updateSql')">
          改
        </el-button>
      </div>
      <div>
        <el-button type="primary" icon="el-icon-s-order" @click="handleFormat()">格式化</el-button>
        <el-button type="primary" icon="el-icon-s-promotion" @click="handleQuery()">请求</el-button>
      </div>
    </div>

    <div class="query-display">
      <el-row :gutter="20">
        <el-col :span="12">
          <!-- <el-input
            ref='query-text'
            class="query-text"
            type="textarea"
            :rows="20"
            placeholder="输入"
            v-model="searchObj.content"
          ></el-input> -->
          <codemirror
            ref="myCm"
            v-model="searchObj.content"
            :options="cmOptions"
            @focus="onCmFocus"
            @blur="onCmBlur"
            class="code"
            placeholder="输入"
          ></codemirror>
          <div :key="index" v-for="(query, index) in queryList" class="history">
            <el-tag
              style="margin-top: 5px"
              class="querylist-tag"
              @click="clickTag(query)"
              @close="handleClose(query)"
              closable
            >
              {{ query }}
            </el-tag>
          </div>
        </el-col>

        <el-col :span="12">
          <el-input
            class="query-text"
            type="textarea"
            :rows="20"
            placeholder="响应"
            v-model="resp"
          ></el-input>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { formatSdl } from 'format-graphql';
import { codemirror } from 'vue-codemirror';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/edit/closebrackets.js';
import 'codemirror/theme/ambiance.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/theme/idea.css';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/hint/sql-hint.js';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/fold/xml-fold';
import 'codemirror/addon/display/placeholder.js';
const CodeMirrorOri = require('codemirror/lib/codemirror');

const queryHint = [
  '_aggregate',
  '_all',
  '_and',
  '_asc',
  '_avg',
  '_between',
  '_columns',
  '_count',
  '_data',
  '_desc',
  '_distinct',
  '_distinct_count',
  '_distinct_on',
  '_do_nothing',
  '_eq',
  '_group_by',
  '_gt',
  '_gte',
  '_having',
  '_in',
  '_include_all',
  '_include_any',
  '_is_null',
  '_like',
  '_limit',
  '_lt',
  '_lte',
  '_max',
  '_min',
  '_neq',
  '_not',
  '_offset',
  '_on_conflict',
  '_or',
  '_order_by',
  '_set',
  '_sum',
  '_update_all',
  '_update_columns',
  '_values',
  '_where',
  'delete',
  'insert',
  'mutation',
  'query',
  'update',
];
const sysHint = [
  'name',
  'id',
  'owner',
  'update_by',
  'update_time',
  'adtag',
  'version',
  'belong_territory',
  'create_by',
  'create_time',
  'delete_time',
  'ext',
];
const SQL = {
  insertSql: `mutation insert {
  table_name(_values: {name: ""})
}`,
  deleteSql: `mutation delete {
  table_name(_where: {id: "xxx"})
}`,
  updateSql: `mutation update {
    table_name(_where: {id: ""}, _set: {name: ""})
}`,
  querySql: `{
  hcp(_where: {register_status: 2}, _order_by: {create_time: _desc}, _limit: 10) {
    id
    name
    hco {
      name
    }
  }
}`,
};
function set(str) {
  const obj = {};
  const words = str.split(' ');
  for (let i = 0; i < words.length; ++i) obj[words[i]] = true;
  return obj;
}
CodeMirrorOri.defineMIME('graphql', {
  name: 'sql',
  keywords: set(queryHint.join(' ')),
});
export default {
  components: {
    codemirror,
  },
  data() {
    return {
      searchObj: {
        content: '',
      },
      resp: '',
      queryList: [],
      cmOptions: {
        placeholder: '',
        autorefresh: true,
        mode: 'graphql', // 选择对应代码编辑器的语言，我这边选的是数据库，根据个人情况自行设置即可
        indentWithTabs: false, // 在缩进时，是否需要把 n*tab宽度个空格替换成n个tab字符，默认为false
        // smartIndent: true, // 自动缩进，设置是否根据上下文自动缩进（和上一行相同的缩进量）。默认为true。
        lineNumbers: false, // 是否在编辑器左侧显示行号。
        matchBrackets: true, // 匹配括号
        cursorHeight: 1, // 光标高度。默认为1，也就是撑满行高。对一些字体，设置0.85看起来会更好。
        lineWrapping: true, // 自动换行
        // readOnly: this.readOnly, //是否只读
        theme: 'idea', // 主题配置
        // autofocus: true,//是否在初始化时自动获取焦点。默认情况是关闭的。但是，在使用textarea并且没有明确指定值的时候会被自动设置为true。
        // eslint-disable-next-line prettier/prettier
        extraKeys: { Ctrl: 'autocomplete' }, // 自定义快捷键
        // keyMap: "sublime", // sublime编辑器效果
        autoCloseBrackets: true, // 在键入时将自动关闭括号和引号
        hintOptions: {
          // 自定义提示选项
          // 当匹配只有一项的时候是否自动补全
          completeSingle: false,
          hint: this.handleShowHint,
        },
        // autoCloseBrackets: true, // 在键入时将自动关闭括号和引号
        matchTags: { bothTags: true },
      },
      product: '',
      appOptions: [],
    };
  },
  watch: {},
  mounted() {
    const str = localStorage.getItem('query-list');
    const data = JSON.parse(str);
    if (data && typeof data === 'object') {
      this.queryList = data;
    }
    // 代码提示功能 当用户有输入时，显示提示信息
    this.$refs.myCm.codemirror.on('inputRead', (cm) => {
      cm.showHint();
    });
    this.loadAppOption();
  },
  methods: {
    loadAppOption() {
      const url = this.$api.getAppList;
      const data = { page_size: 999999 };
      const cb = (data) => {
        this.appOptions = data.data;
        this.product = this.appOptions[0].id;
      };
      this.$request({ url, data, cb });
    },
    clickTag(query) {
      const str = this.formatStr(query);
      if (this.searchObj.content !== '') {
        this.searchObj.content += `\n${str}`;
      } else {
        this.searchObj.content += str;
      }
    },
    handleClose(query) {
      this.queryList.splice(this.queryList.indexOf(query), 1);
      localStorage.setItem('query-list', JSON.stringify(this.queryList));
    },
    querySql(type) {
      if (this.searchObj.content !== '') {
        this.searchObj.content += `\n${SQL[type]}`;
      } else {
        this.searchObj.content += SQL[type];
      }
    },
    onCmBlur() {
      this.$refs.myCm.$el?.childNodes[1].setAttribute('style', 'border-color: #dcdfe6;');
    },
    onCmFocus() {
      this.$refs.myCm.$el?.childNodes[1].setAttribute('style', 'border-color: #409eff;');
    },
    handleShowHint(cmInstance /* hintOptions */) {
      const cursor = cmInstance.getCursor();
      const token = cmInstance.getTokenAt(cursor);
      const listArr = [...queryHint, ...sysHint].filter((item) => {
        return item.includes(token.string);
      });
      // console.log('listArr', listArr);
      if (listArr.length) {
        return {
          list: listArr.map((item) => {
            return {
              text: item,
              displayText: item,
              className: 'query-hint',
            };
          }),
          from: { ch: token.start, line: cursor.line },
          to: { ch: token.end, line: cursor.line },
        };
      }
    },
    // changes(CodeMirror) {
    //   console.log('CodeMirror', CodeMirror);
    // },
    getSelection() {
      const editor = this.$refs.myCm.codemirror;
      // console.log(editor.getSelection(), this.searchObj.content);
      return editor.getSelection();
    },
    formatStr(str, tips = false) {
      let newStr = str;
      let formatStr = str;
      if (this.getSelection() !== '') {
        formatStr = this.getSelection();
      }
      try {
        newStr = newStr.replaceAll(
          formatStr,
          formatSdl(formatStr.replaceAll('.', '__________')).replaceAll('__________', '.'),
        );
      } catch (e) {
        if (tips) {
          this.$message({
            showClose: true,
            message: e.message,
            type: 'warning',
          });
        }
      }
      return newStr;
    },
    handleFormat() {
      this.searchObj.content = this.formatStr(this.searchObj.content, true);
    },
    handleQuery() {
      const url = this.$api.queryData;
      const type = 'post';
      let queryCmd = this.searchObj.content;
      if (this.getSelection() !== '') {
        queryCmd = this.getSelection();
      }
      console.log(queryCmd);
      if (queryCmd === '') {
        this.$message({
          message: '请输入命令后执行',
          type: 'warning',
        });
        return;
      }
      const data = {
        query: queryCmd,
      };
      const client = {
        product: this.product,
      };
      const cb = (data) => {
        this.$message({
          message: '请求成功',
          type: 'success',
        });
        let obj = data.data;
        try {
          obj = _.isObject(data.data) ? data.data : JSON.parse(data.data);
        } catch (err) {
          console.log(err);
        }
        this.resp = JSON.stringify(obj, null, 2);
        this.queryList.unshift(
          this.formatStr(queryCmd)
            .replace(' ', '')
            .replace('\n', '')
            .replace(/mutation/g, 'mutation '),
        );
        // 去重
        this.queryList = [...new Set(this.queryList)];
        // 最大30条
        if (this.queryList.length > 30) {
          this.queryList.length = 30;
        }
        localStorage.setItem('query-list', JSON.stringify(this.queryList));
      };
      this.$request({ url, cb, type, data, client, tst: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  justify-content: space-between;
  .query-button {
    display: flex;
    .el-button {
      height: 40px;
    }
  }
}
// .query-display {
::v-deep .CodeMirror {
  overflow: hidden;
  height: 432px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 5px 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep pre.CodeMirror-line {
  overflow: hidden;
  .cm-keyword {
    color: #0088ff;
    font-weight: 600;
  }
}
.querylist-tag {
  z-index: 0;
  position: relative;
  border: 1px solid #dcdfe6;
  background-color: #fff;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
}
.query-text {
  z-index: 99;
}
// }
</style>
