<template>
  <div class="box">
    <el-card class="box-card">
      <div slot="header">
        <h3>{{ title }}</h3>
      </div>
      <el-card>
        <h4>基本信息</h4>
        <el-form :model="basicInfo" label-width="125px" ref="basicForm" label-position="top">
          <el-form-item
            label="名称"
            prop="name"
            :rules="[{ required: true, message: '请输入', trigger: 'change' }]"
          >
            <el-input v-model="basicInfo.name" autocomplete="off" style="width: 50%"></el-input>
          </el-form-item>
          <el-form-item label="流程key" prop="keys">
            <el-select
              v-model="basicInfo.keys"
              disabled
              placeholder="请选择"
              style="width: 50%"
              multiple
            >
              <el-option
                v-for="obj in processKeyList"
                :key="obj.id"
                :label="obj.label"
                :value="obj.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="请选择函数"
            prop="cloud_func_name"
            :rules="[{ required: true, message: '请选择', trigger: 'change' }]"
          >
            <el-select
              v-show="basicInfo.cloud_func_name"
              v-model="basicInfo.cloud_func_name"
              placeholder="请选择"
              style="width: 50%"
              @focus="openTableDiaLog"
            ></el-select>
            <el-button v-show="!basicInfo.cloud_func_name" @click="buttonOpenDia">
              选择函数
            </el-button>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              type="textarea"
              :rows="2"
              v-model="basicInfo.remark"
              maxlength="50"
              show-word-limit
              autocomplete="off"
              style="width: 50%"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card style="margin-top: 10px" v-if="basicInfo.cloud_func_name">
        <h4>参数映射</h4>
        <el-form inline label-width="125px" label-position="top">
          <template v-for="(item, index) in paramsInfo.params">
            <div :key="index" class="params-form">
              <el-form-item label="审批流输出参数" style="width: 40%">
                <el-input v-model="item.source" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="云函数输入参数" style="width: 40%; margin-left: 10px">
                <el-select v-model="item.name" placeholder="请选择" style="width: 100%">
                  <el-option
                    v-for="(opt, index2) in paramsInfo.options"
                    :key="index2"
                    :label="opt.label"
                    :value="opt.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-button @click="addParamsItem(index)" class="change-item-button">+</el-button>
              <el-button
                :disabled="paramsInfo.params.length === 1"
                @click="deleteParamsItem(index)"
                class="change-item-button"
              >
                -
              </el-button>
            </div>
          </template>
        </el-form>
      </el-card>
      <el-dialog title="选择函数" :visible.sync="dialogVisible">
        <div style="margin-bottom: 15px">
          <el-form label-width="70px">
            <el-form-item label="函数名">
              <el-input placeholder="请输入内容" v-model="func_name">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="searchCloudFunList"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-table
          :data="cloudFunList"
          ref="singleTable"
          highlight-current-row
          v-loadmore="loadMoreFunList"
          @current-change="handleSelectChange"
          height="450"
          style="width: 100%; overflow: auto"
        >
          <el-table-column prop="func_name" label="函数名"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              {{ statusList[scope.row.status] }}
            </template>
          </el-table-column>
          <el-table-column prop="ctime" label="创建时间"></el-table-column>
          <el-table-column prop="mtime" label="修改时间"></el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="setCloudFunName">保 存</el-button>
        </div>
      </el-dialog>
      <div class="button-group">
        <el-button type="info" @click="goBack">返回</el-button>
        <el-button type="primary" @click="submitCloudDetail">提交</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
const statusList = {
  0: '正常',
  1: '删除',
  Creating: '函数创建中',
  CreateFailed: '函数创建失败（如果已生成函数信息，则可以删除后再创建）',
  Active: '函数可用',
  Updating: '函数更新中',
  UpdateFailed: '函数更新失败',
  Publishing: '函数版本发布中',
  PublishFailed: '函数版本发布失败',
  Deleting: '函数删除中',
  DeleteFailed: '函数删除失败',
};
export default {
  data() {
    return {
      statusList,
      id: '',
      title: '选择云函数自定义事件',
      cloudFunId: '',
      basicInfo: {
        name: '',
        keys: [],
        cloud_func_name: '',
        remark: '',
      },
      paramsInfo: {
        params: [
          {
            source: '',
            name: '',
            type: '',
          },
        ],
        options: [],
      },
      processKeyList: [],
      dialogVisible: false,
      cloudFunList: [],
      pageIndex: 1,
      pageSize: 20,
      total: 0,
      func_name: '',
      currentRow: null,
      // 记录列表页状态
      eventTypes: '',
      statusTab: '',
    };
  },
  created() {
    const { id, statusTab, eventTypes } = this.$route.query;
    this.id = id;
    this.statusTab = statusTab;
    this.eventTypes = eventTypes;
    if (id) {
      this.getProcessActionDetail(id);
    }
  },
  methods: {
    addParamsItem(index) {
      this.paramsInfo.params.splice(index + 1, 0, {
        source: '',
        name: '',
        type: '',
      });
    },
    deleteParamsItem(index) {
      this.paramsInfo.params.splice(index, 1);
    },
    loadMoreFunList() {
      if (this.cloudFunList.length >= this.total) {
        return;
      }
      this.pageIndex += 1;
      this.getCloudFuncList();
    },
    searchCloudFunList() {
      this.pageSize = 10;
      this.pageIndex = 1;
      this.cloudFunList = [];
      this.getCloudFuncList();
    },
    getCloudFuncList() {
      this.$request({
        url: this.$api.getFuncList,
        data: {
          page: this.pageIndex - 1,
          size: this.pageSize,
          search_key: this.func_name,
        },
        showLoading: false,
        cb: ({ list, total }) => {
          this.cloudFunList = [
            ...this.cloudFunList,
            ...list?.filter((item) => item.func_type === 0 || item.func_type === 4),
          ];
          this.total = Number(total);
        },
      });
    },
    openTableDiaLog(e) {
      e.preventDefault();
      this.dialogVisible = true;
      this.func_name = '';
      this.getCloudFuncList();
    },
    buttonOpenDia() {
      this.dialogVisible = true;
      this.func_name = '';
      this.getCloudFuncList();
    },
    getProcessActionDetail(id) {
      this.$request({
        url: this.$api.getProcessActionById,
        data: { id },
        cb: ({ process }) => {
          if (process) {
            const { name, remark, template, keys } = process;
            this.basicInfo = {
              name,
              remark,
              keys,
              cloud_func_name: template.cloud_func.name,
            };
            this.paramsInfo.params = template.cloud_func.params.length
              ? template.cloud_func.params
              : [
                  {
                    source: '',
                    name: '',
                    type: '',
                  },
                ];
            if (template.cloud_func.name) {
              this.$request({
                url: this.$api.getFuncList,
                data: {
                  page: 0,
                  size: 1,
                  search_key: template.cloud_func.name,
                },
                showLoading: false,
                cb: ({ list }) => {
                  const paramsInfo = list[0].param_in;
                  try {
                    if (paramsInfo) {
                      const paramsList = JSON.parse(paramsInfo).properties;
                      this.paramsInfo.options = Object.keys(paramsList).map((key) => ({
                        value: key,
                        label: key,
                        type: paramsList[key].type,
                      }));
                    }
                  } catch (err) {
                    console.error('errr', err);
                  }
                },
              });
            }
          }
        },
      });
    },
    goBack() {
      this.$router.push({
        name: 'workflow-event-list',
        query: {
          statusTab: this.statusTab,
          eventTypes: this.eventTypes,
        },
      });
    },
    submitCloudDetail() {
      this.$refs.basicForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const detailData = {
          category: 2, // 1是通知事件， 2是云函数
          name: this.basicInfo.name,
          remark: this.basicInfo.remark,
          status: 1,
          template: {
            cloudFunc: {
              name: this.basicInfo.cloud_func_name,
              params: this.paramsInfo.params.map((item) => {
                const paramType =
                  this.paramsInfo.options?.find((opt) => opt.value === item.name)?.type || '';
                return {
                  ...item,
                  type: paramType,
                };
              }),
            },
          },
        };
        if (!this.id) {
          this.createEvent(detailData);
        } else {
          delete detailData.status;
          this.updateEvent({
            id: this.id,
            ...detailData,
          });
        }
      });
    },
    createEvent(data) {
      this.$request({
        url: this.$api.createProcessAction,
        data,
        cb: (resdata) => {
          if (resdata.id) {
            this.$message.success('提交成功');
            this.goBack();
          }
        },
      });
    },
    updateEvent(data) {
      this.$request({
        url: this.$api.updateProcessAction,
        data,
        cb: (resdata) => {
          if (resdata) {
            this.$message.success('提交成功');
            this.goBack();
          }
        },
      });
    },
    handleSelectChange(val) {
      this.currentRow = val;
    },
    setCloudFunName() {
      if (this.currentRow) {
        const { func_name, id, param_in } = this.currentRow;
        console.log('func_name', func_name);
        this.basicInfo.cloud_func_name = func_name;
        try {
          if (param_in) {
            const paramsList = JSON.parse(param_in).properties;
            this.paramsInfo.options = Object.keys(paramsList).map((key) => ({
              value: key,
              label: key,
              type: paramsList[key].type,
            }));
          }
        } catch (err) {
          console.error('errr', err);
        }
        if (this.cloudFunId !== id) {
          this.paramsInfo.params = [
            {
              source: '',
              name: '',
              type: '',
            },
          ];
        }
        this.cloudFunId = id;
      }
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  .button-group {
    display: flex;
    margin-top: 20px;
  }
  .pagination {
    margin-top: 30px;
    text-align: right;
  }
  ::v-deep .current-row {
    background: #f2f3f8;
  }
  .params-form {
    display: flex;
    align-items: flex-end;
    .change-item-button {
      font-size: 14px;
      margin-bottom: 22px;
    }
  }
}
</style>
