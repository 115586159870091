<template>
  <div ref="editor" class="editor-container"></div>
</template>

<script>
// import * as monaco from 'monaco-editor';
import layoutSchema from './schema/layout.json';
import listSchema from './schema/list.json';
import detailSchema from './schema/detail.json';
import mutationSchema from './schema/mutation.json';

export default {
  components: {},
  data() {
    return {
      editor: null,
      value: '{}',
      isLoaded: false,
    };
  },
  async created() {
    this.initEvent();
    // 向父页面发送消息
    window.parent.postMessage(
      {
        origin: 'EDITOR',
        type: 'READY',
      },
      '*',
    );
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.dispose();
    }
    window.removeEventListener('message', this.init);
  },
  methods: {
    initEvent() {
      window.addEventListener('message', this.init);
    },
    async init(event) {
      if (event.origin !== window.location.origin) {
        // 确保消息来自可信任的源
        return;
      }
      if (event?.data?.origin !== 'EDITOR') {
        return;
      }
      if (!this.isLoaded) {
        await this.loadScript('https://cdn.nges.tencent.com/static/min/vs/loader.js');
        this.isLoaded = true;
      }
      this.value = event.data?.value || '{}';
      this.initializeMonaco();
    },
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    // 提取并检查 JSON 中的 {{ }} 包裹的 JavaScript 代码
    checkEmbeddedJavaScript() {},
    initializeMonaco() {
      let jsonString = this.value;
      try {
        jsonString = JSON.stringify(JSON.parse(jsonString), null, 2);
      } catch (err) {
        console.error(err);
      }
      const self = this;
      // 通过cdn引入加速构建速度
      window.require.config({ paths: { vs: 'https://cdn.nges.tencent.com/static/min/vs' } });
      window.require(['vs/editor/editor.main'], function () {
        self.editor = window.monaco.editor.create(self.$refs.editor, {
          value: jsonString,
          tabSize: 2,
          language: 'json',
          theme: 'vs-dark', // 设置编辑器主题
          readOnly: false, // 设置编辑器是否只读
          automaticLayout: true, // 自动调整布局
          fontSize: 16, // 设置字体大小
          checkJs: true,
          // ...其他配置项
        });
        // 添加内容变化的监听器
        self.editor.onDidChangeModelContent(() => {
          self.checkEmbeddedJavaScript();
          // 当编辑器内容变化时，这个函数会被调用
          // 你可以在这里执行你需要的操作，例如获取最新的内容

          // 获取编辑器当前的内容
          const currentValue = self.editor.getValue();

          // 向父页面发送消息
          window.parent.postMessage(
            {
              value: currentValue,
              origin: 'EDITOR',
              type: 'CHANGE',
            },
            '*',
          );
        });
        function getSchemaBasedOnType(json) {
          const listConf = {
            uri: 'http://nges/list.json', // 一个 URI，用于标识 schema（可以是任意 URI）
            fileMatch: ['*'], // 一个文件匹配模式数组，用于将 schema 应用到特定的文件
            schema: listSchema,
          };
          const detailConf = {
            uri: 'http://nges/detail.json', // 一个 URI，用于标识 schema（可以是任意 URI）
            fileMatch: ['*'], // 一个文件匹配模式数组，用于将 schema 应用到特定的文件
            schema: detailSchema,
          };
          const mutationConf = {
            uri: 'http://nges/mutation.json', // 一个 URI，用于标识 schema（可以是任意 URI）
            fileMatch: ['*'], // 一个文件匹配模式数组，用于将 schema 应用到特定的文件
            schema: mutationSchema,
          };
          const layoutConf = {
            uri: 'http://nges/layout.json', // 一个 URI，用于标识 schema（可以是任意 URI）
            fileMatch: ['*'], // 一个文件匹配模式数组，用于将 schema 应用到特定的文件
            schema: layoutSchema,
          };
          let schemas = [];
          if (json.layout_type === 'list') {
            schemas = [listConf];
          } else if (json.layout_type === 'detail') {
            schemas = [detailConf];
          } else if (['create', 'edit'].includes(json.layout_type)) {
            schemas = [mutationConf];
          } else {
            schemas = [layoutConf, listConf, detailConf, mutationConf];
          }
          return schemas;
        }

        function updateSchema() {
          const model = self.editor.getModel();
          const value = model.getValue();
          try {
            const json = JSON.parse(value);
            const schemas = getSchemaBasedOnType(json);
            if (schemas) {
              window.monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                validate: true,
                schemas,
              });
            }
          } catch (e) {
            console.error('Invalid JSON:', e);
          }
        }

        self.editor.onDidChangeModelContent(updateSchema);
        updateSchema(); // Initial schema update
      });
    },
  },
};
</script>

<style scoped>
.editor-container {
  height: 100%;
  width: 100%;
  border: 1px solid #c4c4c4; /* 设置边框 */
  min-height: 200px; /* 设置最小高度 */
}
</style>
