<template>
  <scroll-bar>
    <div class="menu-wrapper-title" @click="goHome">
      <i class="el-icon-menu"></i>
      <span>{{ titlte }}</span>
    </div>
    <el-menu
      :router="true"
      mode="vertical"
      :default-active="$route.name"
      :collapse="!isCollapse"
      background-color="#3c4a58"
      text-color="#fff"
      active-text-color="#FFF"
    >
      <sidebar-item :menu-list="menuList"></sidebar-item>
    </el-menu>
  </scroll-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import ScrollBar from '@/components/ScrollBar';
import SidebarItem from './SidebarItem';
import config from '@/config';

export default {
  components: { SidebarItem, ScrollBar },
  props: {
    isCollapse: {
      type: Boolean,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('user', {
      menuList: 'getMenuList',
    }),
    titlte() {
      if (config.sign) {
        return `重楼管理系统-${config.sign}`;
      }
      return '重楼管理系统';
    },
  },
  methods: {
    goHome() {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-wrapper-title {
  cursor: pointer;
}
</style>
