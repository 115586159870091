import { request, newAxios } from './axios';

const api = {
  // 登录
  login: '/api/access/pb/cmd/AccountServer/AccountLogin/Login',
  loginGetPhone: '/api/access/pb/cmd/AccountServer/AccountBase/GetPhoneByTmpToken',
  sendSmsCode: '/api/access/pb/cmd/AccountServer/AccountLogin/SendSmsCode',
  loginWithCode: '/api/access/pb/cmd/AccountServer/AccountLogin/LoginWithPhone',

  // 单点登录
  getTenantSSOConfigList:
    '/api/access/pb/cmd/AccountAdminServer/AccountAdminConfig/GetTenantSSOConfigList',
  getTenantSSOConfigInfo:
    '/api/access/pb/cmd/AccountAdminServer/AccountAdminConfig/GetTenantSSOConfigInfo',
  metaCreateTenantSSOConfig:
    '/api/access/pb/cmd/AccountAdminServer/AccountAdminConfig/CreateTenantSSOConfig',
  metaUpdateTenantSSOConfig:
    '/api/access/pb/cmd/AccountAdminServer/AccountAdminConfig/UpdateTenantSSOConfig',
  metaDeleteTenantSSOConfig:
    '/api/access/pb/cmd/AccountAdminServer/AccountAdminConfig/DeleteTenantSSOConfig',
  metaSetTenantSSOConfig:
    '/api/access/pb/cmd/AccountAdminServer/AccountAdminConfig/SetTenantSSOConfig',
  getWebLoginConfig: '/api/access/pb/cmd/AccountServer/AccountConfig/GetWebLoginConfig',
  loginWithOAuth: '/api/access/pb/cmd/AccountServer/AccountLogin/LoginWithOAuth',

  // 用户信息汇总
  getUserinfo: '/api/access/pb/cmd/AccountServer/AccountBase/GetUserInfo',
  getTenantInfo: '/api/access/pb/cmd/TenantServer/TenantSystem/GetTenantInfo', // 租户信息
  getUserMenuTree: '/api/access/pb/cmd/AuthServer/AuthPermission/GetUserMenuTree', // 用户菜单
  getUserElementList: '/api/access/pb/cmd/AuthServer/AuthPermission/GetUserElementList', // 用户菜单

  // 上传接口
  getUploadSign: '/api/access/pb/cmd/ApiServer/Cos/GetUploadSign',

  // 元数据接口
  queryData: '/api/access/pb/cmd/DataServer/DataService/GraphqlQuery',
  metaGetObjectList: '/api/access/pb/cmd/DataAdminServer/MetadataService/GetObjectList',
  metaGetObjectField: '/api/access/pb/cmd/DataAdminServer/MetadataService/GetObjectField',
  GetFieldsWithCondition:
    '/api/access/pb/cmd/DataAdminServer/MetadataService/GetFieldsWithCondition',
  metaGetObjectByName: '/api/access/pb/cmd/DataServer/MetadataService/GetObjectsByNames',
  metaGetObjectByRelatedWorkflow:
    '/api/access/pb/cmd/Workflow.v2admin/ProcessTriggerService/GetObjectByRelatedWorkflow',
  metaGetObjectNameList: '/api/access/pb/cmd/DataAdminServer/MetadataService/GetObjectNameList',
  metaGetObjectByName: '/api/access/pb/cmd/DataServer/MetadataService/GetObjectsByNames',

  metaGetTenantObjectFields:
    '/api/access/pb/cmd/DataAdminServer/MetadataService/GetTenantObjectFields',
  metaUpdateRelation: '/api/access/pb/cmd/DataAdminServer/MetadataService/UpdateRelation',
  metaUpdateWorkflow: '/api/access/pb/cmd/DataAdminServer/MetadataService/UpdateWorkflow',
  metaUpdateWorkflowConfig:
    '/api/access/pb/cmd/DataAdminServer/MetadataService/UpdateWorkflowConfig',
  metaGetObjectDetail: '/api/access/pb/cmd/DataAdminServer/MetadataService/GetObjectDetail',
  metaAddObject: '/api/access/pb/cmd/DataAdminServer/MetadataService/AddObject',
  metaUpdateObject: '/api/access/pb/cmd/DataAdminServer/MetadataService/UpdateObject',
  metaDeleteObject: '/api/access/pb/cmd/DataAdminServer/MetadataService/DeleteObject',
  metaGetObjectFields: '/api/access/pb/cmd/DataAdminServer/MetadataService/GetObjectFields',
  metaAddField: '/api/access/pb/cmd/DataAdminServer/MetadataService/AddField',
  metaUpdateField: '/api/access/pb/cmd/DataAdminServer/MetadataService/UpdateField',
  metaDeleteField: '/api/access/pb/cmd/DataAdminServer/MetadataService/DeleteField',
  metaSetFieldEncrypt: '/api/access/pb/cmd/DataAdminServer/MetadataService/SetFieldEncrypt',
  metaGenOnlineErGraph: '/api/access/pb/cmd/DataAdminServer/MetadataOtherInfo/GenOnlineErGraph', // ER图展示
  metaGetPackageList: '/api/access/pb/cmd/DataAdminServer/MetadataOtherInfo/GetPackageList',
  getObjectRoleTreePermission:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/GetObjectRoleTreePermission',
  setObjectRolePermission:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/SetRoleObjectColumnPermission',
  setObjectRolePermissionBatch:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/SetRoleObjectColumnPermissionBatch',
  metaGetIndexesByObjectID:
    '/api/access/pb/cmd/DataAdminServer/MetadataService/GetIndexesByObjectID',
  metaGetFieldIndexesByID: '/api/access/pb/cmd/DataAdminServer/MetadataService/GetFieldIndexesByID',
  metaAddIndex: '/api/access/pb/cmd/DataAdminServer/MetadataService/AddIndex',
  metaDeleteIndex: '/api/access/pb/cmd/DataAdminServer/MetadataService/DeleteIndex',
  checkFormulaFieldExpression:
    '/api/access/pb/cmd/DataAdminServer/MetadataService/CheckFormulaFieldExpression',

  metaGetTagList: '/api/access/pb/cmd/DataAdminServer/MetadataSync/GetTagList',
  metaSetTag: '/api/access/pb/cmd/DataAdminServer/MetadataSync/SetTag',
  metaGetMetadataByTag: '/api/access/pb/cmd/DataAdminServer/MetadataSync/GetMetadataByTag',
  metaGetKvDataByTag: '/api/access/pb/cmd/DataAdminServer/KvSync/GetKvDataByTag',
  metaDiffMetadataByTag: '/api/access/pb/cmd/DataAdminServer/MetadataSync/DiffMetadataByTag',
  metaDiffKvDataByTag: '/api/access/pb/cmd/DataAdminServer/KvSync/DiffKvDataByTag',
  metaSyncMetadataByTag: '/api/access/pb/cmd/DataAdminServer/MetadataSync/SyncMetadataByTag',
  metaSyncKvdataByTag: '/api/access/pb/cmd/DataAdminServer/KvSync/SyncKvDataByTag',
  metaSyncTenantData: '/api/access/pb/cmd/DataAdminServer/MetadataSync/SyncTenantData',
  metaGetRemoteTagList: '/api/access/pb/cmd/DataAdminServer/MetadataSync/GetRemoteTagList',
  metaImportRemoteTagReq: '/api/access/pb/cmd/DataAdminServer/MetadataSync/ImportRemoteTag',
  metaSetWorkFlowTag: '/api/access/pb/cmd/Workflow.v2admin/AdminService/SetTag',
  metaDiffWorkFlowByTag: '/api/access/pb/cmd/Workflow.v2admin/AdminService/DiffByTag',
  metaGetWorkFlowByTag: '/api/access/pb/cmd/Workflow.v2admin/AdminService/GetWorkflowDataByTag',
  metaSyncWorkFlowData: '/api/access/pb/cmd/Workflow.v2admin/AdminService/SyncData',
  getSyncTenantList: '/api/access/pb/cmd/TenantServer/TenantSystem/GetSyncTenantList',

  // 分享规则
  getSharingRules: '/api/access/pb/cmd/AuthAdminServer/SharingRules/GetSharingRules',
  createSharingRules: '/api/access/pb/cmd/AuthAdminServer/SharingRules/CreateSharingRules',
  updateSharingRules: '/api/access/pb/cmd/AuthAdminServer/SharingRules/UpdateSharingRules',
  delSharingRules: '/api/access/pb/cmd/AuthAdminServer/SharingRules/DelSharingRules',
  // 员工组织
  getOrganizationList:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminOrganization/GetOrganizationList',
  getUserGroupList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminGroup/GetGroupList',
  getUserGroup: '/api/access/pb/cmd/AuthAdminServer/AuthAdminGroup/GetGroup',
  addUserGroup: '/api/access/pb/cmd/AuthAdminServer/AuthAdminGroup/AddGroup',
  updateUserGroup: '/api/access/pb/cmd/AuthAdminServer/AuthAdminGroup/UpdateGroup',
  deleteUserGroup: '/api/access/pb/cmd/AuthAdminServer/AuthAdminGroup/DeleteGroup',

  // 元数据自定义筛选
  getObjectFilter: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/GetObjectFilter',
  getObjectFilterList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/GetObjectFilterList',
  addObjectFilter: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/AddObjectFilter',
  updateObjectFilter: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/UpdateObjectFilter',
  deleteObjectFilter: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/DeleteObjectFilter',

  // 布局接口
  getLayoutList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminLayout/GetLayoutList',
  metaAddLayoutInfo: '/api/access/pb/cmd/AuthAdminServer/AuthAdminLayout/AddLayoutInfo',
  getLayoutInfo: '/api/access/pb/cmd/AuthAdminServer/AuthAdminLayout/GetLayoutInfo',
  metaUpdateLayoutInfo: '/api/access/pb/cmd/AuthAdminServer/AuthAdminLayout/UpdateLayoutInfo',
  deleteLayoutInfo: '/api/access/pb/cmd/AuthAdminServer/AuthAdminLayout/DeleteLayoutInfo',
  getLayoutHistoryList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminLayout/GetLayoutHistoryList',
  releaseLayoutJson: '/api/access/pb/cmd/AuthAdminServer/AuthAdminLayout/ReleaseLayoutJson',
  // graphql
  getObjectsByNames: '/api/access/pb/cmd/DataServer/MetadataService/GetObjectsByNames',
  getLayoutDistribute: '/api/access/pb/cmd/AuthAdminServer/AuthAdminLayout/GetLayoutDistribute',
  setLayoutDistribute: '/api/access/pb/cmd/AuthAdminServer/AuthAdminLayout/SetLayoutDistribute',
  getLayoutDetailList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminLayout/GetLayoutDetailList',

  // 效验规则
  getValidateRules: '/api/access/pb/cmd/DataAdminServer/RuleService/GetValidateRules',
  greateValidateRule: '/api/access/pb/cmd/DataAdminServer/RuleService/AddValidateRule',
  updateValidateRule: '/api/access/pb/cmd/DataAdminServer/RuleService/UpdateValidateRule',
  enableValidateRule: '/api/access/pb/cmd/DataAdminServer/RuleService/UpdateRuleStatus',
  deleteValidateRule: '/api/access/pb/cmd/DataAdminServer/RuleService/DeleteValidateRule',
  getValidateRuleDetail: '/api/access/pb/cmd/DataAdminServer/RuleService/GetValidateRule',
  checkValidateRule: '/api/access/pb/cmd/DataAdminServer/RuleService/CheckValidateRule',
  getComplianceRules: '/api/access/pb/cmd/DataAdminServer/RuleService/GetComplianceRules',
  addComplianceRule: '/api/access/pb/cmd/DataAdminServer/RuleService/AddComplianceRule',
  updateComplianceRule: '/api/access/pb/cmd/DataAdminServer/RuleService/UpdateComplianceRule',
  deleteComplianceRule: '/api/access/pb/cmd/DataAdminServer/RuleService/DeleteComplianceRule',
  checkComplianceRuleScript:
    '/api/access/pb/cmd/DataAdminServer/RuleService/CheckComplianceRuleScript',

  // 触发器
  getTriggers: '/api/access/pb/cmd/DataAdminServer/MetadataService/GetTriggers',
  updateTriggers: '/api/access/pb/cmd/DataAdminServer/MetadataService/UpdateTriggers',
  // 工作流
  getEditProcessList: '/api/access/pb/cmd/Workflow.v2admin/EditProcessService/GetEditProcessList',
  createEditProcess: '/api/access/pb/cmd/Workflow.v2admin/EditProcessService/CreateEditProcess',
  updateEditProcess: '/api/access/pb/cmd/Workflow.v2admin/EditProcessService/UpdateEditProcess',
  deleteEditProcess: '/api/access/pb/cmd/Workflow.v2admin/EditProcessService/DeleteEditProcess',
  getEditProcessById: '/api/access/pb/cmd/Workflow.v2admin/EditProcessService/GetEditProcessById',
  submitEditProcess: '/api/access/pb/cmd/Workflow.v2admin/EditProcessService/SubmitEditProcess',
  saveGraphQLQuery: '/api/access/pb/cmd/Workflow.v2admin/EditProcessService/SaveGraphQLQuery',
  getGraphQLQuery: '/api/access/pb/cmd/Workflow.v2admin/EditProcessService/GetGraphQLQuery',
  enableEditProcess: '/api/access/pb/cmd/Workflow.v2admin/EditProcessService/EnableEditProcess',
  disableEditProcess: '/api/access/pb/cmd/Workflow.v2admin/EditProcessService/DisableEditProcess',
  /**
   *  审批流管理 流程管理
   */
  getListProcess: '/api/access/pb/cmd/Workflow.v2admin/ProcessService/ListProcess',
  createNewProcess: '/api/access/pb/cmd/Workflow.v2admin/ProcessService/CreateNewProcess',
  deleteProcess: '/api/access/pb/cmd/Workflow.v2admin/ProcessService/DeleteProcess',
  exportProcessMaterial: '/api/access/pb/cmd/Workflow.v2admin/ProcessService/ExportProcessMaterial',
  generateProcessMaterial:
    '/api/access/pb/cmd/Workflow.v2admin/ProcessService/GenerateProcessMaterial',
  getProcessInstanceList:
    '/api/access/pb/cmd/Workflow.v2admin/ProcessInstanceService/GetProcessInstanceList',
  getProcessInstanceLog:
    '/api/access/pb/cmd/Workflow.v2admin/ProcessInstanceService/GetProcessInstanceLog',
  getProcessLogConfigure: '/api/access/pb/cmd/Workflow.v2admin/AdminService/GetConfigure',
  setProcessLogConfigure: '/api/access/pb/cmd/Workflow.v2admin/AdminService/SetConfigure',
  getApprovalUserAttributes: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTag/GetTagValueList',
  getApprovalUserListByTag: '/api/access/pb/cmd/AuthAdminServer/AuthAdminUser/GetUserListByTag',
  getProcessActionList:
    '/api/access/pb/cmd/Workflow.v2admin/ProcessActionService/GetProcessActionList',
  updateProcessAction:
    '/api/access/pb/cmd/Workflow.v2admin/ProcessActionService/UpdateProcessAction',
  createProcessAction:
    '/api/access/pb/cmd/Workflow.v2admin/ProcessActionService/CreateProcessAction',
  deleteProcessAction:
    '/api/access/pb/cmd/Workflow.v2admin/ProcessActionService/DeleteProcessAction',
  getProcessActionById:
    '/api/access/pb/cmd/Workflow.v2admin/ProcessActionService/GetProcessActionById',
  enableProcessAction:
    '/api/access/pb/cmd/Workflow.v2admin/ProcessActionService/EnableProcessAction',
  disableProcessAction:
    '/api/access/pb/cmd/Workflow.v2admin/ProcessActionService/DisableProcessAction',
  getWxAppList: '/api/access/pb/cmd/NgesAdminServer/NgesAdminApp/GetWxAppList',
  GetOfficialAccountTemplateList:
    '/api/access/pb/cmd/Workflow.v2admin/AdminService/GetOfficialAccountTemplateList',
  // 云函数
  getCloudFuncList: '/api/access/pb/cmd/FaasAdminServer/AdminService/GetFuncList',
  getCloudPublishList: '/api/access/pb/cmd/FaasAdminServer/AdminService/GetPublishList',

  // 账号管理
  AccountGetUserList: '/api/access/pb/cmd/AccountAdminServer/AccountAdminUser/GetUserList',
  AccountGetTouristList: '/api/access/pb/cmd/AccountAdminServer/AccountAdminUser/GetTouristList',
  AccountGetUserInfo: '/api/access/pb/cmd/AccountAdminServer/AccountAdminUser/GetUserInfo',
  AccountUpdateUserPassword:
    '/api/access/pb/cmd/AccountAdminServer/AccountAdminUser/UpdateUserPassword',
  AccountUpdateUserStatus:
    '/api/access/pb/cmd/AccountAdminServer/AccountAdminUser/UpdateUserStatus',
  AccountGetUserInfoReq: '/api/access/pb/cmd/AccountAdminServer/AccountAdminUser/GetUserInfoReq',
  UpdateLoginAccount: '/api/access/pb/cmd/AccountAdminServer/AccountAdminUser/ImportUserInfo',
  unbindUserAppByUin: '/api/access/pb/cmd/AccountAdminServer/AccountAdminUser/UnbindUserAppByUin',

  // 用户管理
  getUserList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminUser/GetUserList',
  getUser: '/api/access/pb/cmd/AuthAdminServer/AuthAdminUser/GetUserInfo',
  getBatchUserInfo: '/api/access/pb/cmd/AccountServer/AccountBase/BatchQueryUserInfoEx',
  createUser: '/api/access/pb/cmd/AuthAdminServer/AuthAdminUser/AddUserInfo',
  updateUser: '/api/access/pb/cmd/AuthAdminServer/AuthAdminUser/UpdateUserInfo',
  deleteUser: '/api/access/pb/cmd/AuthAdminServer/AuthAdminUser/DeleteUserInfo',

  updateUserJob: '/api/access/pb/cmd/AuthAdminServer/AuthAdminUser/UpdateUserJob',
  getUserJob: '/api/access/pb/cmd/AuthAdminServer/AuthAdminUser/GetUserJobList',
  updateUserRole: '/api/access/pb/cmd/AuthAdminServer/AuthAdminUser/UpdateUserRole',
  updateUserPassword: '/api/access/pb/cmd/AccountAdminServer/AccountAdminUser/UpdateUserPassword',
  getUserRole: '/api/access/pb/cmd/AuthAdminServer/AuthAdminUser/GetUserRoleList',

  // 标签管理   https://git.woa.com/eyao/CRM/EyaoPlatform/blob/feature/r3sp4/iam-user-tag/proto/AuthAdminServer/AuthAdminTag.proto
  getTagsList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTag/GetTagValueList',
  getUserTagInfo: '/api/access/pb/cmd/AuthAdminServer/AuthAdminUser/GetUserTagInfo', // 获取用户标签信息
  getTagGroupList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTag/GetTagAllList',
  addTagGroup: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTag/AddTagInfo',
  addTagValueToGroup: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTag/AddTagValueBatch',
  updateTagGroupInfo: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTag/UpdateTagInfo',
  updateTagValueInfo: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTag/UpdateTagValue',
  deleteTagValue: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTag/DeleteTagValue',
  getTagValueUserList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTag/GetTagValueUserList',
  addTagValueUserBatch: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTag/AddTagValueUserBatch',
  deleteTagValueUser: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTag/DeleteTagValueUser',
  deleteTagValueUserBatch:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminTag/DeleteTagValueUserBatch',

  // 岗位管理
  getJobList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminJob/GetJobList',
  getJob: '/api/access/pb/cmd/AuthAdminServer/AuthAdminJob/GetJobInfo',
  getJobTree: '/api/access/pb/cmd/AuthAdminServer/AuthAdminJob/GetJobTree',
  createJob: '/api/access/pb/cmd/AuthAdminServer/AuthAdminJob/AddJobInfo',
  updateJob: '/api/access/pb/cmd/AuthAdminServer/AuthAdminJob/UpdateJobInfo',
  deleteJob: '/api/access/pb/cmd/AuthAdminServer/AuthAdminJob/DeleteJobInfo',

  getJobRole: '/api/access/pb/cmd/AuthAdminServer/AuthAdminJob/GetJobRoleList',
  updateJobRole: '/api/access/pb/cmd/AuthAdminServer/AuthAdminJob/UpdateJobRole',

  // 员工组织管理
  getOrganizationTree:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminOrganization/GetOrganizationTree',
  getOrganizationList:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminOrganization/GetOrganizationList',
  addOrganizationInfo:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminOrganization/AddOrganizationInfo',
  getOrganizationInfo:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminOrganization/GetOrganizationInfo',
  updateOrganizationInfo:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminOrganization/UpdateOrganizationInfo',
  deleteOrganizationInfo:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminOrganization/DeleteOrganizationInfo',

  // 角色管理
  getRoleList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/GetRoleList',
  getRoleTree: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/GetRoleTree',
  getRole: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/GetRoleInfo',
  createRole: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/AddRoleInfo',
  updateRole: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/UpdateRoleInfo',
  copyRole: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/CopyRoleInfo',
  deleteRole: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/DeleteRoleInfo',

  // 角色权限
  getRolePermission: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/GetRolePermission',
  setRolePermission: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/SetRolePermission',
  addRolePermission: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/AddRolePermission',
  removeRolePermission: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/RemoveRolePermission',
  getRoleObjectView: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/GetRoleObjectView',
  setRoleObjectView: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/SetRoleObjectView',
  getRoleObjectPermission:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/GetRoleObjectPermission',
  setRoleObjectColumnPermission:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/SetRoleObjectColumnPermission',
  getRoleObjectColumnPermission:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/GetRoleObjectColumnPermission',

  // 应用管理
  getAppList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminApplication/GetApplicationList',
  createApp: '/api/access/pb/cmd/AuthAdminServer/AuthAdminApplication/AddApplicationInfo',
  updateApp: '/api/access/pb/cmd/AuthAdminServer/AuthAdminApplication/UpdateApplicationInfo',
  deleteApp: '/api/access/pb/cmd/AuthAdminServer/AuthAdminApplication/DeleteApplicationInfo',
  refreshAppSecret:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminApplication/RefreshApplicationSecret',
  getLanguageList: '/api/access/pb/cmd/CrmAdminServer/CrmAdminService/GetTenantLanguageList',
  getLanguage: '/api/access/pb/cmd/CrmAdminServer/CrmAdminService/GetTenantLanguage',
  updateLanguage: '/api/access/pb/cmd/AuthAdminServer/AuthAdminApplication/UpdateTenantLanguage',

  // 菜单管理
  getMenuList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminMenu/GetMenuList',
  getMenu: '/api/access/pb/cmd/AuthAdminServer/AuthAdminMenu/GetMenuInfo',
  getMenuTree: '/api/access/pb/cmd/AuthAdminServer/AuthAdminMenu/GetMenuTree',
  getMenuBaseTree: '/api/access/pb/cmd/AuthAdminServer/AuthAdminMenu/GetMenuBaseTree',
  createMenu: '/api/access/pb/cmd/AuthAdminServer/AuthAdminMenu/AddMenuInfo',
  updateMenu: '/api/access/pb/cmd/AuthAdminServer/AuthAdminMenu/UpdateMenuInfo',
  deleteMenu: '/api/access/pb/cmd/AuthAdminServer/AuthAdminMenu/DeleteMenuInfo',
  releaseMenuJson: '/api/access/pb/cmd/AuthAdminServer/AuthAdminMenu/ReleaseMenuJson',

  // 接口管理
  getApiList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminApi/GetApiList',
  getApi: '/api/access/pb/cmd/AuthAdminServer/AuthAdminApi/GetApiInfo',
  createApi: '/api/access/pb/cmd/AuthAdminServer/AuthAdminApi/AddApiInfo',
  updateApi: '/api/access/pb/cmd/AuthAdminServer/AuthAdminApi/UpdateApiInfo',
  deleteApi: '/api/access/pb/cmd/AuthAdminServer/AuthAdminApi/DeleteApiInfo',

  // 文件管理
  getFileList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminFile/GetFileList',
  getFile: '/api/access/pb/cmd/AuthAdminServer/AuthAdminFile/GetFileInfo',
  createFile: '/api/access/pb/cmd/AuthAdminServer/AuthAdminFile/AddFileInfo',
  updateFile: '/api/access/pb/cmd/AuthAdminServer/AuthAdminFile/UpdateFileInfo',
  deleteFile: '/api/access/pb/cmd/AuthAdminServer/AuthAdminFile/DeleteFileInfo',

  // 元素管理
  getElementList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminElement/GetElementList',
  getElement: '/api/access/pb/cmd/AuthAdminServer/AuthAdminElement/GetElementInfo',
  checkElement: '/api/element/check',
  createElement: '/api/access/pb/cmd/AuthAdminServer/AuthAdminElement/AddElementInfo',
  updateElement: '/api/access/pb/cmd/AuthAdminServer/AuthAdminElement/UpdateElementInfo',
  deleteElement: '/api/access/pb/cmd/AuthAdminServer/AuthAdminElement/DeleteElementInfo',

  // 对象管理
  getObjectList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/GetObjectList',
  getObject: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/GetObjectInfo',
  createObject: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/AddObjectInfo',
  updateObject: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/UpdateObjectInfo',
  deleteObject: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/DeleteObjectInfo',
  getObjectDetailList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/GetObjectDetailList',
  getObjectRolePermission:
    '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/GetObjectRolePermission',

  // 对象字段管理
  getObjectFieldList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/GetObjectFieldList',
  createObjectField: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/AddObjectFieldInfo',
  updateObjectField: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/UpdateObjectFieldInfo',
  deleteObjectField: '/api/access/pb/cmd/AuthAdminServer/AuthAdminObject/DeleteObjectFieldInfo',

  // 版本管理
  getVersionTagList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTool/GetTagList',
  getRemoteTagList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTool/GetRemoteTagList',
  getImportRemoteTag: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTool/ImportRemoteTag',
  getAuthByTag: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTool/GetAuthByTag',
  setTag: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTool/SetTag',
  diffAuth: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTool/DiffAuth',
  syncAuth: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTool/SyncAuth',
  deleteAuthTag: '/api/access/pb/cmd/AuthAdminServer/AuthAdminTool/DeleteAuthTag',
  getConfigTagList: '/api/access/pb/cmd/TenantServer/TenantVersion/GetConfigTagList',
  setConfigTag: '/api/access/pb/cmd/TenantServer/TenantVersion/SetConfigTag',
  deleteConfigTag: '/api/access/pb/cmd/TenantServer/TenantVersion/DeleteConfigTag',
  getRemoteConfigTagList: '/api/access/pb/cmd/TenantServer/TenantVersion/GetRemoteConfigTagList',
  importRemoteConfigTag: '/api/access/pb/cmd/TenantServer/TenantVersion/ImportRemoteConfigTag',

  // 云函数 接口文档: https://iwiki.woa.com/pages/viewpage.action?pageId=1392647216
  getFuncList: '/api/access/pb/cmd/FaasAdminServer/AdminService/GetFuncList',
  getRemoteFuncList: '/api/access/pb/cmd/FaasAdminServer/AdminService/GetRemoteFuncList',
  funcPublish: '/api/access/pb/cmd/FaasAdminServer/AdminService/Publish',
  delFunc: '/api/access/pb/cmd/FaasAdminServer/AdminService/DelFunc',
  getPublishList: '/api/access/pb/cmd/FaasAdminServer/AdminService/GetPublishList',
  funcCreatePublish: '/api/access/pb/cmd/FaasAdminServer/AdminService/CreatePublish',
  getVcsVersions: '/api/access/pb/cmd/FaasAdminServer/AdminService/GetVcsVersionList',
  getJobs: '/api/access/pb/cmd/FaasAdminServer/AdminService/GetJobs',
  setJobs: '/api/access/pb/cmd/FaasAdminServer/AdminService/SetJobs',
  setFunc: '/api/access/pb/cmd/FaasAdminServer/AdminService/SetFunc',
  syncFunc: '/api/access/pb/cmd/FaasAdminServer/AdminService/SyncFunc', // 同步
  pullFunc: '/api/access/pb/cmd/FaasAdminServer/CmdService/Pull',
  createFunc: '/api/access/pb/cmd/FaasAdminServer/AdminService/CreateFunc',
  updateCode: '/api/access/pb/cmd/FaasAdminServer/AdminService/UpdateCode',
  pullCloudFunctionDetail: '/api/access/pb/cmd/FaasAdminServer/CmdService/Pull',
  pushCloudFunctionDetail: '/api/access/pb/cmd/FaasAdminServer/CmdService/Push',
  getSubscribe: '/api/access/pb/cmd/FaasAdminServer/AdminService/GetSubscribe',
  setSubscribe: '/api/access/pb/cmd/FaasAdminServer/AdminService/SetSubscribe',
  getFuncByTag: '/api/access/pb/cmd/FaasAdminServer/AdminService/GetFuncByTag',
  diffFaaSByTag: '/api/access/pb/cmd/FaasAdminServer/AdminService/DiffFaaSByTag',
  faasSetTag: '/api/access/pb/cmd/FaasAdminServer/AdminService/SetTag',
  syncFaaSData: '/api/access/pb/cmd/FaasAdminServer/AdminService/SyncFaaSData',

  // 发版管理
  getUpdateInfo: '/api/access/pb/cmd/TenantServer/TenantSystem/GetLoginConfig',
  setUpdateInfo: '/api/access/pb/cmd/TenantServer/TenantSystem/UpdateLoginConfig',

  // 通知
  getMessagingTemplateList:
    '/api/access/pb/cmd/MessagingAdminServer/MessagingAdmin/GetMessagingTemplateList',
  updateMessagingTemplateStatus:
    '/api/access/pb/cmd/MessagingAdminServer/MessagingAdmin/UpdateMessagingTemplateStatus',
  addMessagingTemplate:
    '/api/access/pb/cmd/MessagingAdminServer/MessagingAdmin/AddMessagingTemplate',
  getMessagingTemplate:
    '/api/access/pb/cmd/MessagingAdminServer/MessagingAdmin/GetMessagingTemplate',
  updateMessagingTemplate:
    '/api/access/pb/cmd/MessagingAdminServer/MessagingAdmin/UpdateMessagingTemplate',
  deleteMessagingTemplate:
    '/api/access/pb/cmd/MessagingAdminServer/MessagingAdmin/DeleteMessagingTemplate',
  // token管理
  clearTenantUserToken:
    '/api/access/pb/cmd/AccountAdminServer/AccountAdminTool/ClearTenantUserToken',
  getUserTokenByUinOrKey:
    '/api/access/pb/cmd/AccountAdminServer/AccountAdminTool/GetUserTokenByUinOrKey',
  updateTokenExpiration:
    '/api/access/pb/cmd/AccountAdminServer/AccountAdminTool/UpdateTokenExpiration',

  // 国际化
  getInternationConfigList:
    '/api/access/pb/cmd/CrmAdminServer/CrmAdminService/GetInternationConfigList',
  updateInternationConfigInfo:
    '/api/access/pb/cmd/CrmAdminServer/CrmAdminService/UpdateInternationConfigInfo',

  GetSmsTemplate: '/api/access/pb/cmd/MessagingAdminServer/MessagingAdmin/GetSmsTemplate',

  getCurrentEnv: '/api/access/pb/cmd/DataAdminServer/MetadataOtherInfo/GetCurrentEnv',

  // 合规引擎
  getMeetingComplianceRules:
    '/api/access/pb/cmd/EventsAdminServer/Meeting/GetMeetingComplianceRules',
  getObjectRuleList: '/api/access/pb/cmd/DataAdminServer/RuleService/GetObjectRuleList',

  // 权限包
  addPermSetToUser: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/AddRoleUser',
  addPermSetToJob: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/AddRoleJob',
  deletePermSetToUser: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/DeleteRoleUser',
  deletePermSetToJob: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/DeleteRoleJob',
  getPermSetUserList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/GetRoleUserList',
  getPermSetJobList: '/api/access/pb/cmd/AuthAdminServer/AuthAdminRole/GetRoleJobList',
  getAppConfig: '/api/access/pb/cmd/NgesServer/NgesApp/GetAppConfig',
};

const axios = newAxios;
export { api, request, axios };
