<template>
  <div class="menu-wrapper">
    <template v-for="item in menuList">
      <template v-if="condition(item) && (item.path || item.children.length > 0)">
        <router-link
          class="menu-router-link"
          v-if="!item.children || item.children.length == 0"
          :to="{ name: item.path }"
          :key="item.id"
        >
          <div class="router-name router-no-children" v-if="item.name">
            <i v-if="item.icon" :class="item.icon"></i>
            {{ item.name }}
          </div>
        </router-link>
        <el-submenu v-else :index="item.path || item.id.toString()" :key="-item.id">
          <template slot="title">
            <i v-if="item.icon" :class="item.icon"></i>
            <span v-if="item.name">{{ item.name }}</span>
          </template>
          <template v-for="child in item.children">
            <template v-if="condition(child)">
              <el-menu-item
                :class="
                  child.children.length &&
                  threeMenu[child.path] &&
                  threeMenu[child.path].isClick &&
                  isClickMenu
                    ? 'is-active'
                    : ''
                "
                :route="{ name: child.path }"
                :key="child.id"
                :index="child.path"
              >
                <router-link :to="{ name: child.path }" :key="child.id" class="menu-router-link">
                  <div class="router-name" v-if="child.name" @click="handleMenuStyle(child)">
                    <i v-if="child.icon" :class="child.icon"></i>
                    {{ child.name }}
                  </div>
                </router-link>
              </el-menu-item>
            </template>
          </template>
        </el-submenu>
      </template>
    </template>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
export default {
  name: 'SidebarItem',
  props: {
    isNest: {
      type: Boolean,
      default: false,
    },
    menuList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isClickMenu: false,
      threeMenu: {
        'layout-manage': { isClick: false },
        'new-metadata': { isClick: false },
        'iam-entry': { isClick: false },
        'meta-entry': { isClick: false },
      },
    };
  },

  computed: {
    routeList() {
      const list = [];
      this.$router.options.routes.forEach((route) => {
        if (route.name) {
          list.push(route.name);
        }
        if (route.children) {
          route.children.forEach((item) => {
            if (item.name) {
              list.push(item.name);
            }
          });
        }
      });
      return list;
    },
  },
  mounted() {
    Object.keys(this.threeMenu).forEach((key) => {
      console.log('--key---', key);
      if (this.$route.path.indexOf(key) !== -1) {
        this.threeMenu[key].isClick = true;
      }
    });
    if (this.$route.matched.length >= 2) {
      this.isClickMenu = true;
    }
  },
  methods: {
    // 处理含有三级菜单的二级菜单（新元数据列表）点击后，不高亮问题
    handleMenuStyle(child) {
      if (child.children.length) {
        Object.keys(this.threeMenu).forEach((key) => {
          this.threeMenu[key].isClick = key === child.path;
        });
      }
      this.isClickMenu = child.children.length;
    },
    getRouterLink(name) {
      const routerList = this.$router.options.routes;
      for (let i = 0; i < routerList.length; i++) {
        const router = routerList[i];
        if (router.name === name) {
          return router.path;
        }
      }
    },
    condition(menu) {
      const isReg = this.routeList.includes(menu.path) || this.routeList.includes(menu.code);
      return menu.display === 1 && isReg;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-router-link {
  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .router-name {
    text-align: center;
  }
  .router-no-children {
    padding: 0 20px;
    text-align: left;
    i {
      margin-right: 5px;
      font-size: 18px;
      padding-left: 4px;
    }
  }
  .is-active {
    background-color: red !important;
  }
}
</style>
