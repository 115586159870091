<template>
  <div class="box">
    <el-card class="box-card">
      <div slot="header">
        <h3>{{ title }}</h3>
      </div>
      <el-card>
        <h4>基本信息</h4>
        <el-form :model="basicInfo" label-width="125px" ref="basicForm" label-position="top">
          <el-form-item
            label="名称"
            prop="name"
            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
          >
            <el-input v-model="basicInfo.name" autocomplete="off" style="width: 50%"></el-input>
          </el-form-item>
          <el-form-item label="流程key" prop="keys">
            <el-select
              v-model="basicInfo.keys"
              placeholder="请选择"
              style="width: 50%"
              multiple
              disabled
            >
              <el-option
                v-for="obj in processKeyList"
                :key="obj.id"
                :label="obj.label"
                :value="obj.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              type="textarea"
              :rows="2"
              v-model="basicInfo.remark"
              maxlength="50"
              show-word-limit
              autocomplete="off"
              style="width: 50%"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <div>
        <el-card style="width: 100%; margin-top: 20px">
          <h4>通知模版</h4>
          <el-form
            :model="templateInfo"
            :rules="templateInfoFormRule"
            label-width="125px"
            ref="templateForm"
            label-position="top"
          >
            <el-form-item label="类型" prop="subref">
              <el-select
                v-model="templateInfo.subref"
                placeholder="请选择"
                style="width: 50%"
                @change="onSubrefChange"
              >
                <el-option
                  v-for="obj in noticeTemplateTypes"
                  :key="obj.label"
                  :label="obj.label"
                  :value="obj.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="templateInfo.subref === 'lclmsg'"
              prop="system_type"
              label="站内信类型"
            >
              <el-select
                style="width: 50%"
                filterable
                v-model="templateInfo.system_type"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in systemMessageTypeList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="企微消息类型"
              prop="wxmsg_type"
              v-if="templateInfo.subref === 'wxmsg'"
            >
              <el-radio-group v-model="templateInfo.wxmsg_type">
                <el-radio :label="1">文本消息</el-radio>
                <el-radio :label="2">小程序通知消息</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-divider></el-divider>
            <h4>
              接收人设置
              <span style="color: #f56c6c; margin-left: 5px">*至少选填一项</span>
            </h4>
            <el-form-item label="指定接收人变量" prop="to2">
              <el-select v-model="templateInfo.to2" placeholder="请选择" style="width: 50%">
                <el-option
                  v-for="obj in candidateUserAtributes"
                  :key="obj.label"
                  :label="obj.label"
                  :value="obj.value"
                ></el-option>
              </el-select>
              <el-input
                v-if="templateInfo.to2 === 'specify_variable'"
                v-model="templateInfo.variable"
                placeholder="请输入指定变量"
                style="width: 314px; margin-left: 4px"
              ></el-input>
              <el-tooltip effect="dark" placement="top-start">
                <i class="el-icon-question" style="margin-left: 10px" />
                <div style="width: 200px" slot="content">
                  该选择框仅用于在审批流中配置审批人节点触发通知消息时的接收人设置指定接收人变量
                </div>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="指定其他接收人" prop="to">
              <el-select
                v-model="templateInfo.to"
                multiple
                filterable
                remote
                placeholder="请选择"
                :remote-method="getCandidateUserList"
                style="width: 50%"
                collapse-tags
              >
                <el-option
                  v-for="(item, index) in candidateUserList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="通知标题" prop="title">
              <el-input
                class="notice-title"
                v-model="templateInfo.title"
                autocomplete="off"
                style="width: 50%"
              ></el-input>
              <el-button
                @click="openMetaDiagLog('title')"
                style="margin-left: 10px"
                :disabled="basicInfo.keys.length !== 1"
              >
                插入对象字段
              </el-button>
            </el-form-item>
            <el-form-item v-if="needPlatform" prop="platform" label="通知终端">
              <el-checkbox-group v-model="templateInfo.platform">
                <el-checkbox :label="1">小程序端</el-checkbox>
                <el-checkbox :label="2">web端</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="通知内容" prop="content">
              <template v-if="templateInfo.subref === 'wxmsg'">
                <div style="display: flex">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="templateInfo.content"
                    autocomplete="off"
                    style="width: 50%"
                    placeholder="请输入"
                    class="notice-content"
                    v-show="templateInfo.wxmsg_type !== 2"
                  ></el-input>
                  <InputJson
                    v-model="templateInfo.content"
                    v-show="templateInfo.wxmsg_type === 2"
                    class="form-input"
                  />
                  <el-button
                    @click="openMetaDiagLog('content')"
                    style="margin-left: 10px; height: 40px"
                    :disabled="basicInfo.keys.length !== 1"
                  >
                    插入对象字段
                  </el-button>
                </div>
                <a
                  style="color: #0077ff"
                  :href="documentLink"
                  target="_blank"
                  v-if="templateInfo.wxmsg_type === 2"
                >
                  点击查看JSON格式相关文档，仅需填写miniprogram_notice属性下的对象
                </a>
              </template>
              <template v-if="templateInfo.subref !== 'wxmsg'">
                <div v-if="templateInfo.subref === 'email'">
                  <el-radio-group v-model="templateInfo.editor_type" @change="emailMsgTypeChange">
                    <el-radio :label="1">编辑富文本</el-radio>
                    <el-radio :label="2">编辑HTML</el-radio>
                  </el-radio-group>
                </div>
                <template v-if="templateInfo.editor_type !== 2">
                  <el-button
                    @click="openMetaDiagLog('content')"
                    style="margin-left: 0; color: #0069fa"
                    :disabled="basicInfo.keys.length !== 1"
                    type="info"
                  >
                    插入对象字段
                  </el-button>
                  <RichEditor
                    :content="templateInfo.content"
                    @getContent="
                      (e) => {
                        templateInfo.content = e;
                        $refs.templateForm.validateField('content');
                      }
                    "
                  />
                </template>
                <!-- html消息 -->
                <template v-else-if="templateInfo.editor_type === 2">
                  <div class="editor-block">
                    <div class="editor-part">
                      <div class="editor-title">
                        编辑HTML
                        <el-button
                          style="margin-left: 0; color: #0069fa"
                          :disabled="basicInfo.keys.length !== 1"
                          type="info"
                          @click="openMetaDiagLog('content')"
                        >
                          插入对象字段
                        </el-button>
                      </div>
                      <CodeEditor
                        class="code-editor"
                        v-model="templateInfo.content"
                        :options="htmlOptions"
                      />
                      <el-button type="text" @click="openPreview">预览</el-button>
                    </div>
                    <div class="editor-part">
                      <div class="editor-title">自定义脚本</div>
                      <CodeEditor
                        class="code-editor"
                        v-model="templateInfo.script"
                        :options="jsOptions"
                      />
                      <el-button type="text" @click="openHelp">帮助文档</el-button>
                    </div>
                  </div>
                </template>
              </template>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
      <div class="button-group">
        <el-button type="info" @click="goBack">返回</el-button>
        <el-button type="primary" @click="submitNoitceDetail">提交</el-button>
      </div>
    </el-card>
    <select-meta-dialog-vue ref="metaDialog" :object-names="objectNames" @insertion="insertion" />
    <Clipboard ref="copy" :copytext="copytext" />

    <!-- messageBox -->
    <MessageBox ref="previewBox" class="preview-box">
      <template v-slot:content>
        <div v-html="previewContent" />
      </template>
    </MessageBox>
    <MessageBox ref="helpBox">
      <template v-slot:content>
        <div>脚本语法使用js语法（部分支持）和 underscore(_)，注意变量名不要以双下划线开头</div>
        <div>1. 脚本中引用自定义变量，使用 args.xxx，如 args.task_id</div>
        <div>
          2. 脚本中引用元数据变量，使用 {object}.xxx，如
          events_meeting.meeting_name，注意元数据变量仅支持引用HTML模版中使用的字段，其余为undefined
        </div>
        <div>
          3. 模版中可以引用脚本中定义的变量，如 {% foo %}，可以在此处使用表达式计算值，如 {% foo + 1
          %}
        </div>
        <div>当前支持的IO方法：</div>
        <div>- debug_log([any],...) 打印日志，参数：任意类型，返回：无</div>
        <div>
          - get_query([query]) 获取query结果，参数：query字符串，返回：元数据query接口返回data参数
        </div>
        <div>
          - get_field_option([object],[field])
          获取枚举字段的选项，参数：任意对象名、字段名，返回：value到label的映射
        </div>
      </template>
    </MessageBox>
  </div>
</template>

<script>
import { noticeTemplateTypes, candidateUserAtributes } from '../common';
import { throttle } from '@/utils/util';
import selectMetaDialogVue from './select-meta-dialog.vue';
import InputJson from '@/components/inputJson';
import Clipboard from 'nges-common/src/web/components/Clipboard';
import CodeEditor from '../../notice/noticeDetail/editor.vue';
import MessageBox from '@/components/messageBox';
import RichEditor from '@/components/richEditor';
import { difference } from 'lodash';
const _ = { difference };
export default {
  components: {
    selectMetaDialogVue,
    RichEditor,
    InputJson,
    Clipboard,
    CodeEditor,
    MessageBox,
  },
  data() {
    return {
      id: '',
      userId: '',
      title: '通知消息事件详情',
      basicInfo: {
        name: '',
        keys: [],
        remark: '',
      },
      templateInfo: {
        title: '',
        content: '',
        to: [],
        to2: '',
        variable: '',
        subref: '',
        system_type: '',
        wxmsg_type: 1,
        platform: [1, 2],
        editor_type: 1,
        script: '',
      },
      processKeyList: [],
      candidateUserList: [],
      noticeTemplateTypes,
      candidateUserAtributes,
      insertType: '',
      objectNames: [],
      // 记录列表页状态
      statusTab: '',
      eventTypes: '',
      // 自动复制变量
      copytext: '',
      documentLink:
        'https://developer.work.weixin.qq.com/document/path/90236#%E5%B0%8F%E7%A8%8B%E5%BA%8F%E9%80%9A%E7%9F%A5%E6%B6%88%E6%81%AF',
      systemMessageTypeList: [],
      // 代码输入框配置
      htmlOptions: {
        placeholder: '<html></html>',
        autorefresh: true,
        mode: 'text/html',
        matchBrackets: true,
        tabSize: 4,
        lineNumbers: true,
        lineWrapping: true,
      },
      jsOptions: {
        placeholder: 'var a = 1',
        autorefresh: true,
        mode: 'text/javascript',
        matchBrackets: true,
        tabSize: 4,
        lineNumbers: true,
        lineWrapping: true,
      },
      // 校验规则
      templateInfoFormRule: {
        subref: [{ required: true, message: '请选择', trigger: 'blur' }],
        system_type: [{ required: true, message: '请选择', trigger: 'blur' }],
        wxmsg_type: [{ required: true, message: '请选择', trigger: 'blur' }],
        title: [{ required: true, message: '请输入通知标题', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入通知内容' },
          {
            validator: (rule, value, callback) => {
              const err = this.validateContent();
              if (err) {
                callback(err);
              } else {
                callback();
              }
            },
          },
        ],
        platform: [{ required: true, message: '请选择通知终端 ', trigger: 'change' }],
      },
    };
  },
  computed: {
    generateTo2() {
      if (!this.templateInfo.to2) {
        return [];
      }
      return this.templateInfo.to2 === 'specify_variable'
        ? [this.templateInfo.variable]
        : [this.templateInfo.to2];
    },
    generateToType() {
      if (!this.templateInfo.to2) {
        return 0;
      }
      return this.templateInfo.to2 === 'specify_variable' ? 2 : 1;
    },
    previewContent() {
      return this.templateInfo.content.replaceAll('href="mailto', '');
    },
    // 只选了站内信的时候,需要选择终端
    needPlatform() {
      return this.templateInfo.subref === 'lclmsg';
    },
  },
  created() {
    const { id, statusTab, eventTypes } = this.$route.query;
    this.id = id;
    this.statusTab = statusTab;
    this.eventTypes = eventTypes;
    this.getSystemMessageTypes();
    if (id) {
      this.getProcessActionDetail(id);
    }
  },
  methods: {
    onSubrefChange(e) {
      if (e !== 'wxmsg') {
        this.$nextTick(() => {
          this.$refs.quillEditor.initHtml(this.templateInfo.content);
        });
      }
    },
    getCandidateUserList(name = '', delay = 300) {
      if (!name) {
        this.candidateUserList = this.templateInfo?.to?.map((value) => {
          return {
            value,
            label: value.replace(/\((.+?)\)/g, ''),
          };
        });
        return;
      }
      throttle(() => {
        this.$request({
          url: this.$api.getUserList,
          data: {
            page_size: 100,
            page: 1,
            keyword: name,
          },
          showLoading: false,
          cb: ({ data }) => {
            if (data) {
              const map = {};
              this.candidateUserList = [
                ...(this.templateInfo.to || []).map((value) => ({
                  value,
                  label: value.replace(/\((.+?)\)/g, ''),
                })),
                ...data.map((item) => ({
                  label: `${item.name}${item.description ? `-${item.description}` : ''}`,
                  value: `(${item.account})${item.name}${
                    item.description ? `-${item.description}` : ''
                  }`,
                })),
              ].reduce((prev, cur) => {
                map[cur.value] ? '' : (map[cur.value] = true && prev.push(cur));
                return prev;
              }, []);
            } else {
              this.candidateUserList = [];
            }
          },
        });
      }, delay).call(this);
    },
    getProcessActionDetail(id) {
      this.$request({
        url: this.$api.getProcessActionById,
        data: { id },
        cb: ({ process }) => {
          if (process) {
            const { name, remark, keys, template } = process;
            this.basicInfo = {
              name,
              remark,
              keys,
            };

            let generateTo2 = '';
            let generateVariable = '';

            const { to2 } = template.notify;
            if (!to2?.length) {
              generateTo2 = '';
            } else {
              generateTo2 = to2[0];
            }
            // 非有调用方指定变量
            if (
              generateTo2 &&
              !['current_assignee', 'last_assignee', 'starter'].includes(generateTo2)
            ) {
              generateTo2 = 'specify_variable';
              generateVariable = to2[0];
            }
            this.templateInfo = {
              ...template.notify,
              to2: generateTo2,
              variable: generateVariable,
            };
            if (this.templateInfo.subref !== 'wxmsg') {
              this.$refs.quillEditor.initHtml(this.templateInfo.content);
            }
            this.getCandidateUserList('', 0);
            if (keys.length) {
              const key1 = keys[0];
              this.$request({
                url: this.$api.metaGetObjectByRelatedWorkflow,
                data: {
                  process_def_key: key1,
                },
                cb: ({ list }) => {
                  this.objectNames = list.map((i) => i.name);
                },
              });
            }
          }
        },
      });
    },
    goBack() {
      this.$router.push({
        name: 'workflow-event-list',
        query: {
          statusTab: this.statusTab,
          eventTypes: this.eventTypes,
        },
      });
    },
    submitNoitceDetail() {
      this.$refs.basicForm.validate((basicValid) => {
        this.$refs.templateForm.validate((templateValid) => {
          if (!templateValid || !basicValid) {
            return;
          }
          if (!this.templateInfo?.to?.length && !this.templateInfo.to2) {
            this.$message.error('接收人设置至少选填一项');
            return;
          }
          if (this.templateInfo.to2 === 'specify_variable' && !this.templateInfo.variable) {
            this.$message.error('请输入指定变量');
            return;
          }
          const detailData = {
            category: 1, // 1是通知事件， 2是云函数
            status: 1,
            name: this.basicInfo.name,
            remark: this.basicInfo.remark,
            template: {
              notify: {
                editor_type: this.templateInfo.editor_type,
                script: this.templateInfo.script,
                title: this.templateInfo.title,
                content: this.templateInfo.content,
                platform: this.templateInfo.platform,
                to: this.templateInfo.to,
                to2: this.generateTo2,
                to_type: this.generateToType,
                subref: this.templateInfo.subref,
                wxmsg_type: this.templateInfo.wxmsg_type || undefined,
                system_type: this.templateInfo.system_type || undefined,
              },
            },
          };
          if (!this.id) {
            this.createNoticeEvent(detailData);
          } else {
            delete detailData.status;
            this.updateNoticeEvent({
              id: this.id,
              ...detailData,
            });
          }
        });
      });
    },
    createNoticeEvent(data) {
      this.$request({
        url: this.$api.createProcessAction,
        data,
        cb: (resdata) => {
          if (resdata.id) {
            this.$message.success('提交成功');
            this.goBack();
          }
        },
      });
    },
    updateNoticeEvent(data) {
      this.$request({
        url: this.$api.updateProcessAction,
        data,
        cb: (resdata) => {
          if (resdata) {
            this.$message.success('提交成功');
            this.goBack();
          }
        },
      });
    },
    openMetaDiagLog(type) {
      this.insertType = type;
      this.$refs.metaDialog.openDialog();
    },
    insertion(str = '') {
      let selector = '';
      let field = '';
      if (this.insertType === 'title') {
        selector = '.notice-title .el-input__inner';
        field = 'title';
        const dom = document.querySelector(selector);
        this.templateInfo[field] = `${
          this.templateInfo[field].substring(0, dom.selectionStart) +
          str +
          this.templateInfo[field].substring(dom.selectionEnd, dom.textLength)
        }`;
      } else if (this.insertType === 'content' && this.templateInfo.subref !== 'wxmsg') {
        this.templateInfo.content += str;
      } else if (
        this.insertType === 'content' &&
        this.templateInfo.subref === 'wxmsg' &&
        this.templateInfo.wxmsg_type !== 1
      ) {
        this.copytext = str;
        this.$refs.copy.copy();
      } else {
        selector = '.notice-content .el-textarea__inner';
        field = 'content';
        const dom = document.querySelector(selector);
        this.templateInfo[field] = `${
          this.templateInfo[field].substring(0, dom.selectionStart) +
          str +
          this.templateInfo[field].substring(dom.selectionEnd, dom.textLength)
        }`;
      }
    },
    getSystemMessageTypes() {
      this.$request({
        url: this.$api.metaGetObjectByName,
        data: {
          objects: ['message'],
        },
        cb: ({ list }) => {
          if (list.length) {
            const field = list[0].fields.filter(({ name }) => name === 'type');
            this.systemMessageTypeList = field[0].select_one_option.options;
          }
        },
      });
    },
    emailMsgTypeChange() {
      this.templateInfo.content = '';
    },
    openPreview() {
      this.$refs.previewBox.show({
        title: '预览',
        width: '960px',
        top: '30vh',
        confirmText: '返回',
        exitButton: true,
        confirmAction: this.$refs.previewBox.close,
      });
    },
    openHelp() {
      this.$refs.helpBox.show({
        title: '帮助',
        width: '960px',
        top: '30vh',
        confirmText: '返回',
        exitButton: true,
        confirmAction: this.$refs.helpBox.close,
      });
    },
    validateContent() {
      if (this.needPlatform) {
        const platformFlagMap = { 1: 'wx', 2: 'web' };
        const platforms = (this.templateInfo.platform || []).map((item) => platformFlagMap[item]);
        const { content } = this.templateInfo;
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const links = doc.querySelectorAll('a');
        const hrefs = Array.from(links).map(function (link) {
          return link.getAttribute('href');
        });
        if (hrefs.length) {
          for (let i = 0; i < hrefs.length; i++) {
            const href = hrefs[i];
            const regex = /\[\(([a-zA-Z0-9]+)\).*?\]/g;
            let match;
            const plats = [];
            while ((match = regex.exec(href)) !== null) {
              plats.push(match[1]);
            }
            // 如果勾选了，而没有配对应平台的链接，则报错
            if (_.difference(platforms, plats).length) {
              return new Error('请确保为每个选中的终端填写对应的链接。');
            }
            if (_.difference(plats, platforms).length) {
              return new Error('请选择超链接对应的通知终端。');
            }
          }
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  .button-group {
    display: flex;
    margin-top: 20px;
  }
  ::v-deep .ql-toolbar.ql-snow + .ql-container.ql-snow {
    min-height: 200px;
  }
  .form-input {
    width: 50%;
  }
}
.editor-block {
  display: flex;
  .editor-part {
    flex: 1;
    margin-right: 16px;
    .editor-title {
      margin-bottom: 8px;
    }
    .code-editor {
      border: 1px solid black;
      flex: 1;
    }
  }
}

.preview-box {
  ::v-deep .el-dialog__body {
    padding-left: 20px;
  }
}
</style>
