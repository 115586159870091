<template>
  <div>
    <el-dialog :visible.sync="visible" width="600px" :before-close="beforeClose">
      <span slot="title" class="dialog-title">新建自定义云函数</span>
      <div class="cloud-wrapper">
        <div class="sub-title">基本信息</div>
        <el-form
          :model="basicInfo"
          label-width="85px"
          label-position="right"
          ref="basicForm"
          class="form-wrapper"
        >
          <el-form-item
            label="名称"
            prop="name"
            :rules="[{ required: true, message: '请输入', trigger: 'change' }]"
            class="form-item"
          >
            <el-input v-model="basicInfo.name" class="form-input" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="流程key" prop="keys" class="form-item">
            <el-select
              v-model="basicInfo.keys"
              disabled
              placeholder="请选择"
              multiple
              class="form-input"
            ></el-select>
          </el-form-item>
          <el-form-item
            label="选择函数"
            prop="cloud_func_name"
            :rules="[{ required: true, message: '请选择', trigger: 'change' }]"
            class="form-item"
          >
            <el-select
              v-show="basicInfo.cloud_func_name"
              v-model="basicInfo.cloud_func_name"
              placeholder="请选择"
              class="form-input"
              @focus="openCloudTableDiaLog"
            ></el-select>
            <el-button
              v-show="!basicInfo.cloud_func_name"
              @click="buttonOpenSelectFunDia"
              type="info"
              style="color: #0069fa"
            >
              选择函数
            </el-button>
          </el-form-item>
          <el-form-item label="备注" prop="remark" class="form-item">
            <el-input
              type="textarea"
              :rows="2"
              v-model="basicInfo.remark"
              maxlength="50"
              autocomplete="off"
              class="form-input"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="sub-title" v-if="basicInfo.cloud_func_name" style="margin-top: 40px">
          参数映射
        </div>
        <el-form inline label-width="125px" label-position="top" v-if="basicInfo.cloud_func_name">
          <template v-for="(item, index) in paramsInfo.params">
            <div :key="index" class="params-form">
              <el-form-item label="审批流输出参数" style="width: 35%">
                <el-input v-model="item.source" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="云函数输入参数" style="width: 35%; margin-left: 10px">
                <el-select v-model="item.name" placeholder="请选择" style="width: 100%">
                  <el-option
                    v-for="(opt, index2) in paramsInfo.options"
                    :key="index2"
                    :label="opt.label"
                    :value="opt.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-button @click="addParamsItem(index)" class="change-item-button">+</el-button>
              <el-button
                :disabled="paramsInfo.params.length === 1"
                @click="deleteParamsItem(index)"
                class="change-item-button"
              >
                -
              </el-button>
            </div>
          </template>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="handleCloseCreateDia">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </span>
    </el-dialog>
    <SelectCloudfunDialogVue ref="cloudfunDialog" @setCloudFun="setCloudFunName" />
  </div>
</template>
<script>
import SelectCloudfunDialogVue from '../../../../views/workflow/component/select-cloudfun-dialog.vue';
export default {
  name: '',
  components: {
    SelectCloudfunDialogVue,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
      basicInfo: {
        name: '',
        keys: [],
        cloud_func_name: '',
        remark: '',
      },
      cloudFunId: '',
      paramsInfo: {
        params: [
          {
            source: '',
            name: '',
            type: '',
          },
        ],
        options: [],
      },
    };
  },
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    openDiaLog() {
      this.visible = true;
      this.basicInfo.keys = [this.info.process_key];
    },
    handleCloseCreateDia() {
      this.beforeClose();
    },
    beforeClose() {
      this.$refs.basicForm.clearValidate();
      this.visible = false;
      this.basicInfo = {
        name: '',
        keys: [],
        cloud_func_name: '',
        remark: '',
      };
      this.cloudFunId = '';
      this.paramsInfo = {
        params: [
          {
            source: '',
            name: '',
            type: '',
          },
        ],
        options: [],
      };
    },
    addParamsItem(index) {
      this.paramsInfo.params.splice(index + 1, 0, {
        source: '',
        name: '',
        type: '',
      });
    },
    deleteParamsItem(index) {
      this.paramsInfo.params.splice(index, 1);
    },
    openCloudTableDiaLog(e) {
      e.preventDefault();
      this.$refs.cloudfunDialog.openDiaLog();
    },
    buttonOpenSelectFunDia() {
      this.$refs.cloudfunDialog.openDiaLog();
    },
    submit() {
      this.$refs.basicForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const detailData = {
          category: 2, // 1是通知事件， 2是云函数
          name: this.basicInfo.name,
          remark: this.basicInfo.remark,
          status: 1,
          template: {
            cloudFunc: {
              name: this.basicInfo.cloud_func_name,
              params: this.paramsInfo.params.map((item) => {
                const paramType =
                  this.paramsInfo.options?.find((opt) => opt.value === item.name)?.type || '';
                return {
                  ...item,
                  type: paramType,
                };
              }),
            },
          },
        };
        this.createEvent(detailData);
      });
    },
    createEvent(data) {
      this.$request({
        url: this.$api.createProcessAction,
        data,
      }).then(async (res) => {
        if (res.id) {
          this.$message.success('创建成功');
          await this.$emit('getEventOptions');
          this.$emit('addEventValue', res.id);
          this.beforeClose();
        }
      });
    },
    setCloudFunName(row) {
      if (row) {
        const { func_name, id, param_in } = row;
        this.basicInfo.cloud_func_name = func_name;
        try {
          if (param_in) {
            const paramsList = JSON.parse(param_in).properties;
            this.paramsInfo.options = Object.keys(paramsList).map((key) => ({
              value: key,
              label: key,
              type: paramsList[key].type,
            }));
          }
        } catch (err) {
          console.error('errr', err);
        }
        if (this.cloudFunId !== id) {
          this.paramsInfo.params = [
            {
              source: '',
              name: '',
              type: '',
            },
          ];
        }
        this.cloudFunId = id;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/dialog.scss';
.cloud-wrapper {
  max-height: 500px;
  overflow-y: auto;

  .sub-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #1c2028;
  }
  .form-wrapper {
    margin-top: 16px;
    .form-item {
      margin-bottom: 18px;
    }
    .form-input {
      width: 470px;
      height: 32px;
    }
  }
  .params-form {
    display: flex;
    align-items: center;
  }
}
::v-deep .el-form-item__label {
  line-height: 32px;
  color: #384050;
  font-weight: 500;
}
::v-deep .el-form-item__content {
  line-height: 32px;
}

::v-deep .el-input__inner {
  height: 32px;
  border-radius: 2px;
}
::v-deep .el-input__icon {
  line-height: 32px;
}
.change-item-button {
  font-size: 14px;
  margin-top: 43px;
}
</style>
